#monthViewCalendar{
  margin:0;
  table, tbody, th, tr, td{
    display:block;
    background: 0;
  }
  tr{
    display: flex;
    flex-flow: row;
    align-items: stretch;
    flex: 0 0 auto;
    margin-right: -6px;
    // margin-right: -2%;  // Offset remainer of cell percentage
  }
  th, td{
    width: 14.285714286%; // 100/7 = 14.285714286
    margin-bottom:-1px;
    margin-right:-1px;
    padding: 10px;
    background: #EFEFEF;
  }
  th{
    // width: 100%;
    &:before{ font-size: 1rem; display:none;}
    @include respond-to(max, md){
      font-size: 0;
      &:before{display:block;}
    }
    &:nth-of-type(1){ &:before{ content: "Sun"; } }
    &:nth-of-type(2){ &:before{ content: "Mon"; } }
    &:nth-of-type(3){ &:before{ content: "Tue"; } }
    &:nth-of-type(4){ &:before{ content: "Wed"; } }
    &:nth-of-type(5){ &:before{ content: "Thu"; } }
    &:nth-of-type(6){ &:before{ content: "Fri"; } }
    &:nth-of-type(7){ &:before{ content: "Sat"; } }
  }
  td{
    min-height: 100px;
    height: auto;
    background: #FFF;

  }
  caption{
    display:block;
    margin:0 auto;
  }
  .dateLink{
    text-decoration: none;
    + .vevent{
      border-top: 1px solid #efefef;
    }
  }
  .today{
    background: #E4E4E4;
    a{
      color: $primary-color;
    }
    .dateLink + .vevent{
      border-top: 1px solid $primary-color;
    }
  }
  .vevent{
    border: none;
    padding: 10px 0 0 0;
    margin: 10px 0;
  }
}


@include respond-to(max, 610px){
  #monthViewCalendar{
    tr{
      flex-flow: column;
    }
    th{display:none;}
    td{
      width: 100%;
      min-height: auto;
      text-align: left;
    }
    .otherMonth{
      text-align: left;
    }
  }
}
