.sport-colors h2,
.widget-teamButtons .teamname,
// #panelOne .cmsButton,
.textBlockElement h3,
.aggregatorElement h3,
.mailSubscribeElement h3,
.reportTableElement h3,
.tableElement h3,
.pollElement h3 {
	font-family: $primary-font;
	font-size: rem(29px);
	font-weight: 600;
	color: $primary-color;
}
.pageElement,
.textBlockElement,
.rosterElement,
.aggregatorElement,
.tableElement{
  h3{
    padding: 0;
  }
}
h3 span{
  font-size: inherit;
  line-height: 1.2;
  font-weight: inherit;
}
.textBlockElement p,
.textBlockElement li{
	line-height: $base-line-height;
}
.eventPreview th a{
	color:$primary-color;
}

.yieldPageContent{
  padding: 0;
}
.layoutContainer .pageElement, .layoutContainer .hrElement{
      margin: 0 25px 25px 0;
}
.layoutContainer {
    // margin-right: -25px;
		padding: 10px 0;
}
.pageEl:last-of-type{
	.pageElement{
		margin-bottom: 0;
	}
}
.twoColumn6633 .column2 .expandedView, .twoColumn3366 .column1 .expandedView, .twoColumn5050 .expandedView, .threeColumn333333 .expandedView, .threeColumn502525 .expandedView, .threeColumn255025 .expandedView, .threeColumn252550 .expandedView{
	display: table-cell;
}

// Hide empty site banner/banner-graphic/text
.bannerGraphicTextBanner{
  display: none;
}
