.newsAggregatorElement{
  .draftNotice{
    font-size: 12px;
  }
  .even, .odd{
    background-color: transparent;
  }
  a{
    text-decoration: none;
  }
  .aggHeader{
    @extend %agg-headers;
  }
  .item{
    line-height: 1.2;
    border: none;
    // border-bottom: 2px solid #EEE;
    padding: 0 0 10px 0;
    + .item{
      padding-top: 10px;
    }
    &.odd{
      background-color: transparent;
    }

    &.condensed{
      position: relative;
      display: flex;
      // align-items: flex-end;
      .expandedView{
        display: none;
      }
    }
    &.condensed > a{
      // display: block;
      // width:100%;
      display: block;
      width: auto;
      position: absolute;
      overflow: hidden;
      img{
        // display: none;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translateX(-50%) translateY(-50%);
        // width: 100%;
      }
    }
  }
}
.newsAggregatorElement{
  .condensed{
    h4{
      font-family: $primary-font;
      font-size: rem(14px);
      line-height: 1.5;
      font-weight: 600;
      a{
        color: $dark-color;
      }
    }
    .dateAuthor{
      font-family: $primary-font;
      font-size: rem(13px);
      line-height: 1;
      color: $secondary-color;
    }
    .teaser.expandedView{
      display: block;
      padding-top: 10px;
      font-size: rem(12px);
      line-height: 1.5;
    }
  }
  .expanded{
    .newsItemElement{
      h2{
        font-family: $primary-font;
        font-size: rem(14px);
        font-weight: 600;
        line-height: 1.5;
        margin: 0;
        a{
          color: $dark-color;
          &:hover{text-decoration: none;}
        }
      }
    }
    .newsDate, .newsAuthor{
      font-family: $primary-font;
      font-size: rem(13px);
      line-height: 1;
      color: $secondary-color;
    }
    .teaser.expandedView{
      display: block;
      padding-top: 10px;
      font-size: rem(12px);
      line-height: 1.5;
    }
  }
}
// with image - condensed
.newsAggregatorElement{
  .details{
    padding-left: 80px;
  }
  a img{
    width: 65px;
    transform: translateY(5px);
  }
}

.aggregatorElement .extendedOptions{
  font-family: $primary-font;
  font-size: rem(14px);
  margin: 0;
  padding: 10px 0;
  border: none !important;
  background-color: transparent;
  a{ text-decoration: none;}
  .icon{
    padding: 0;
    background: none;
    padding: 0 2px;
    color: $light-color;
    transition: .2s linear;

    &:hover{
      color: $primary-color;
    }
  }
}
