.newsPage{
  .siteContainer{
    // background: #FFF;
  }
}
.newsItemColumn{
  padding-top: 0;
  .newsPreviewThumb{
    width: auto;
    float: none;
    margin: 0;
    width: auto !important;
    max-width: 100%;
    max-height: 75vh;
  }
  .newsItemElement{
    border-bottom: 2px solid $light-color-lt;
    margin-bottom: 20px !important;
    margin-right: 0 !important;
    h2{
      @extend %agg-headers;
      margin: 20px 0;
      &:first-child{
        margin-top: 0;
      }
    }
    h4{
      margin: 0 0 20px 0;
    }
  }
  .newsDate, .newsAuthor{
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 400;
    @include font-smooth();
    color: $dark-color;
  }
  .newsAuthor{
    margin: 10px 0 20px 0;
  }
}
.newsTags{
  font-size: 16px;
  line-height: $base-line-height;
  color: $dark-color;
  a{
    @extend %base-link;
  }
}
