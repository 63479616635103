.page-banner{
  @extend %full-width;
  z-index: -1;
  &.banner-present{
    border-bottom: 5px solid $primary-color;
    margin-top: -70px;
    @include respond-to(max, 1270px){ margin-top: -10px; }
    @include respond-to(max, md){ margin-top: 0; }
  }
}
.page-banner-img{
  width: 100%;
  display: block;
}
#page-banner-header{
  @extend %standard-width;
  position: relative;
  span{
    &:empty{
      display: none;
    }
    display: block;
    background: $primary-color;
    color: #FFF;
    font-size: 44px;
    font-family: $secondary-font;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px 5px 0;
    -webkit-font-smoothing: antialiased;
    @include respond-to(min, 500px){
      display: inline-block;
      padding: 15px 15px 0;
      background: url(../images/news-page-header.svg);
      background-size: cover;
      margin-left: 15px;
      position: absolute;
      bottom: 0;
    }
  }
}
