@charset "UTF-8";
#page-banner-header, .full-width-container .fw-content {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.page-banner, .full-width, .full-width-container {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}

.layoutContainer {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navButton:after {
  content: "";
  display: table;
  clear: both;
}

.newsItemColumn .newsItemElement h2, .newsAggregatorElement .aggHeader, .eventAggregatorElement .aggHeader {
  color: #cc0000;
  font-family: "nimbus-sans-condensed", sans-serif;
  font-size: 2.57143rem;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 25px;
}

@media screen and (max-width: 400px) {
  .newsItemColumn .newsItemElement h2, .newsAggregatorElement .aggHeader, .eventAggregatorElement .aggHeader {
    font-size: 1.85714rem;
  }
}

.sport-colors h2,
.widget-teamButtons .teamname,
.textBlockElement h3,
.aggregatorElement h3,
.mailSubscribeElement h3,
.reportTableElement h3,
.tableElement h3,
.pollElement h3 {
  font-family: "Arial", sans-serif;
  font-size: 2.07143rem;
  font-weight: 600;
  color: #cc0000;
}

.pageElement h3,
.textBlockElement h3,
.rosterElement h3,
.aggregatorElement h3,
.tableElement h3 {
  padding: 0;
}

h3 span {
  font-size: inherit;
  line-height: 1.2;
  font-weight: inherit;
}

.textBlockElement p,
.textBlockElement li {
  line-height: 1.5;
}

.eventPreview th a {
  color: #cc0000;
}

.yieldPageContent {
  padding: 0;
}

.layoutContainer .pageElement, .layoutContainer .hrElement {
  margin: 0 25px 25px 0;
}

.layoutContainer {
  padding: 10px 0;
}

.pageEl:last-of-type .pageElement {
  margin-bottom: 0;
}

.twoColumn6633 .column2 .expandedView, .twoColumn3366 .column1 .expandedView, .twoColumn5050 .expandedView, .threeColumn333333 .expandedView, .threeColumn502525 .expandedView, .threeColumn255025 .expandedView, .threeColumn252550 .expandedView {
  display: table-cell;
}

.bannerGraphicTextBanner {
  display: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #000;
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;
}

.siteContainer {
  position: relative;
  overflow-x: hidden;
  z-index: 1;
}

.siteContainer, #topNav table, #siteFooter > ul {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

#siteFooter {
  width: 100%;
}

#panelOne {
  width: 100%;
}

#panelTwo {
  width: 100%;
}

#panelOne, #panelTwo {
  box-sizing: border-box;
  margin: 0;
}

#pageContentContainer {
  padding: 2em;
}

@media screen and (max-width: 768px) {
  #panelOne, #panelTwo {
    float: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #pageContentContainer {
    display: flex;
    flex-direction: column;
    padding: 1vw;
  }
}

@media screen and (max-width: 768px) {
  .layoutContainer .pe25, .layoutContainer .pe33, .layoutContainer .pe50, .layoutContainer .pe66 {
    width: 100%;
  }
}

@media screen and (min-width: 450px) and (max-width: 768px) {
  .threeColumn502525 .pe25, .threeColumn252550 .pe25 {
    width: 50%;
  }
  .threeColumn502525 .pe50, .threeColumn252550 .pe50 {
    width: 100%;
  }
}

.subNav .drag, .subNav .lock, .cmsNavButton a {
  padding: 6px 20px 6px 8px;
  margin: 1px 0;
}

.relatedSections, #leftHandGraphics, #panelOne .cmsButton {
  display: none;
}

body:not(#home):not(#news) #panelTwo {
  padding-bottom: 4em;
}

body:not(#home):not(#news) #panelTwo #displayBodyContent {
  background-color: #FFF;
  background: url(../images/paper-repeat.jpg);
  padding: 2em;
}

#home #panelTwo #yieldContent {
  padding: 0;
}

#panelOne {
  margin-bottom: 2em;
}

html, body {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #000;
}

body {
  font-size: 100%;
}

.newsTags a {
  color: #cc0000;
  text-decoration: none;
  transition: color .2s linear;
}

.newsTags a:hover {
  color: #660000;
}

.text p, .previewText p, .newsText p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.text ul, .text ol, .previewText ul, .previewText ol, .newsText ul, .newsText ol {
  margin-left: 10px;
  margin-bottom: 1em;
}

.text ul, .previewText ul, .newsText ul {
  list-style-type: disc;
}

.text li, .previewText li, .newsText li {
  margin: 0 0 .5em 10px;
}

.text a, .previewText a, .newsText a {
  color: red;
  text-decoration: none;
  transition: color .2s linear;
}

.text a:hover, .previewText a:hover, .newsText a:hover {
  color: #e60000;
}

.text b, .text strong, .previewText b, .previewText strong, .newsText b, .newsText strong {
  font-weight: bold;
}

.text i, .text em, .previewText i, .previewText em, .newsText i, .newsText em {
  font-style: italic;
}

.text small, .previewText small, .newsText small {
  font-size: .75em;
}

.text mark, .previewText mark, .newsText mark {
  background-color: yellow;
}

.text s, .text strike, .text del, .previewText s, .previewText strike, .previewText del, .newsText s, .newsText strike, .newsText del {
  text-decoration: line-through;
}

.text u, .previewText u, .newsText u {
  text-decoration: underline;
}

.text code, .previewText code, .newsText code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

.text code, .text kbd, .text pre, .text samp, .previewText code, .previewText kbd, .previewText pre, .previewText samp, .newsText code, .newsText kbd, .newsText pre, .newsText samp {
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

.text pre, .previewText pre, .newsText pre {
  display: block;
  padding: 15px;
  margin: 0 0 1rem 0;
  font-size: 90%;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.text blockquote, .previewText blockquote, .newsText blockquote {
  font-family: "nimbus-sans-condensed", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 27px;
  line-height: 1.3333333333;
  color: #cc0000;
  padding: 0 0 1em 1em;
  margin: 1em;
}

.text blockquote:before, .previewText blockquote:before, .newsText blockquote:before {
  content: "“";
  display: inline-block;
  font-weight: 900;
  color: #CCC;
}

.text blockquote:after, .previewText blockquote:after, .newsText blockquote:after {
  content: "”";
  display: inline-block;
  font-weight: 900;
  color: #CCC;
}

.text blockquote p, .previewText blockquote p, .newsText blockquote p {
  display: inline;
}

.text > p:last-child, .text > h1:last-child, .text > h2:last-child, .text > h3:last-child, .text > h4:last-child, .text > h5:last-child, .text > h6:last-child, .text > ol:last-child, .text > ul:last-child, .text > blockquote:last-child, .text > pre:last-child, .previewText > p:last-child, .previewText > h1:last-child, .previewText > h2:last-child, .previewText > h3:last-child, .previewText > h4:last-child, .previewText > h5:last-child, .previewText > h6:last-child, .previewText > ol:last-child, .previewText > ul:last-child, .previewText > blockquote:last-child, .previewText > pre:last-child, .newsText > p:last-child, .newsText > h1:last-child, .newsText > h2:last-child, .newsText > h3:last-child, .newsText > h4:last-child, .newsText > h5:last-child, .newsText > h6:last-child, .newsText > ol:last-child, .newsText > ul:last-child, .newsText > blockquote:last-child, .newsText > pre:last-child {
  margin-bottom: 0;
}

.text ul, .text ol, .previewText ul, .previewText ol, .newsText ul, .newsText ol {
  margin-left: 10px;
  margin-bottom: 1em;
}

.text ul li, .text ol li, .previewText ul li, .previewText ol li, .newsText ul li, .newsText ol li {
  margin-left: 10px;
}

.text ul li > ul, .text ul li > ol, .text ol li > ul, .text ol li > ol, .previewText ul li > ul, .previewText ul li > ol, .previewText ol li > ul, .previewText ol li > ol, .newsText ul li > ul, .newsText ul li > ol, .newsText ol li > ul, .newsText ol li > ol {
  margin-bottom: 0;
}

.tableWrapper table {
  font-size: 16px;
  font-family: "nimbus-sans-condensed", sans-serif;
}

.tableElement .odd {
  background-color: transparent;
}

.tableElement tbody tr {
  border-bottom: 2px solid #000;
}

.tableElement table tr th {
  background: #333;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tableElement .ajax_sort:hover {
  color: #cc0000;
}

.dataTable td, .dataTable th {
  padding: 10px;
}

.textBlockElement h3 {
  font-family: "Arial", sans-serif;
}

.textBlockElement h3 {
  font-size: 2.07143rem;
  font-weight: 600;
  line-height: 1;
  color: #cc0000;
}

#news h2#displayBodyHeader, #news
h2#displayBodyHeader span {
  display: none;
}

#siteFooter {
  background: linear-gradient(to bottom, #222, #000);
  padding: 20px 0;
}

#siteFooter ul {
  list-style: none;
  color: #FFF;
  max-width: 800px;
}

#siteFooter > ul {
  text-align: left;
  position: relative;
  padding-left: 55px;
}

#siteFooter > ul li {
  display: inline-block;
  margin-left: 5px;
}

#siteFooter > ul li:first-child {
  width: 100%;
}

#siteFooter > ul:before {
  content: '';
  display: block;
  width: 45px;
  height: 60px;
  background: url(../images/footer-crest.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#siteFooter a {
  text-decoration: none;
  color: #DDD;
  transition: color .2s linear;
}

#siteFooter a:hover {
  color: #cc0000;
}

#poweredByNGIN a {
  margin-left: -12px;
}

#poweredByNGIN img {
  margin-bottom: -6px;
}

@media screen and (max-width: 768px) {
  #siteFooter > ul {
    text-align: center;
    padding: 0;
  }
  #siteFooter > ul li {
    margin: 0;
  }
  #siteFooter > ul:before {
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    background-position: center;
  }
}

#siteHeader h1:empty,
#siteMiniHeader h1:empty {
  display: none;
}

#accountNav {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9997;
  background-color: #DDD;
}

#accountNav a {
  box-sizing: content-box;
}

#siteHeader .adminToggle {
  position: fixed;
  top: auto;
  bottom: 0;
  right: 0;
  z-index: 10000;
}

#siteHeader #adminNav {
  position: fixed;
  top: auto;
  bottom: 0;
  right: 80px;
  z-index: 10000;
}

#siteHeader #adminNav a {
  margin: 0;
  line-height: 1;
}

#siteHeader .adminToggle, #siteHeader #adminNav {
  top: initial;
  left: initial;
  position: fixed;
  z-index: 9998;
}

#siteHeader #adminNav {
  right: 3px;
  bottom: 5px;
}

#siteHeader #adminNav a {
  margin: 0;
}

#siteHeader .adminToggle {
  right: 145px;
  bottom: 6px;
}

#siteHeader #adminNav {
  height: 40px;
  right: 0;
  bottom: 0;
}

#siteHeader #adminNav a {
  text-decoration: none;
  display: block;
  overflow: hidden;
  height: 40px;
  color: #808F98;
  transition: .2s ease-out;
}

#siteHeader #adminNav a:hover {
  color: #2598C6;
}

#siteHeader #adminNav > a:before {
  text-align: left;
  width: 100%;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  display: block;
  position: relative;
  padding: 0px 0px 0px 60px;
  top: 50%;
  transform: translateY(-50%);
}

#siteHeader #adminNav img {
  display: none;
}

.user_mode #adminNav {
  background: url("../images/switch_off.png") 12px 50% no-repeat;
  background-color: #E5E7E8;
}

.user_mode #adminNav > a:last-of-type:before {
  content: 'Edit Mode';
}

.edit_mode #adminNav {
  background: url("../images/switch_on.png") 12px 50% no-repeat;
  background-color: #E5E7E8;
}

.edit_mode #adminNav > a:before {
  content: 'User Mode';
}

#siteHeader .adminToggle {
  background: url("../images/admin_gear.png") 50% 50% no-repeat;
  height: 40px;
  width: 40px;
  right: 138px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
}

#siteHeader #adminNav .admin-trash {
  position: fixed;
  height: 40px;
  width: auto !important;
  right: 178px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;
}

#siteHeader #adminNav .admin-trash:before {
  display: none !important;
}

#siteHeader #adminNav .admin-site-guide {
  position: fixed;
  height: 40px;
  width: auto !important;
  right: 233px;
  bottom: 0px;
  background-color: #E5E7E8;
  border-right: 1px solid #808F98;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: initial;
  font-weight: 900;
}

#siteHeader #adminNav .admin-site-guide:before {
  display: none !important;
}

.row-1 {
  padding: 0;
  border-bottom: 5px solid #cc0000;
}

.row-1 .pageElement {
  margin: 0;
}

@media screen and (max-width: 768px) {
  #accountNav, #adminNav, .adminToggle {
    display: none;
  }
}

#topNav table, #topNav tbody, #topNav tr, #topNav td {
  display: block;
}

#topNav tr {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
}

#topNav td {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  justify-content: center;
}

#topNav a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-decoration: none;
}

#topNav a:hover {
  text-decoration: none;
}

#topNav td ul {
  box-shadow: none;
  top: 100%;
  left: 0;
}

#topNav td ul li a {
  background: none;
  border: none;
  width: auto;
  padding: 0;
}

#topNav td ul li:hover, #topNav td ul li.hover {
  background-color: transparent;
}

#topNav td ul li:hover > a, #topNav td ul li.hover > a {
  background: none;
  border: none;
}

#topNav ul ul {
  border: none;
  top: 0;
  left: 100%;
}

#topNav {
  min-height: 200px;
}

#topNav a {
  line-height: 1.2;
}

#topNav tbody {
  background-color: #cc0000;
  padding: 15px;
  max-width: 980px;
  margin: 0 0 0 auto;
  position: relative;
}

#topNav tbody:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 0;
  display: block;
  height: 30px;
  width: 100%;
  max-width: 980px;
  background: url(../images/angle.svg) no-repeat;
  background-size: 100% 100%;
}

#topNav tbody:before {
  content: "Maryland";
  position: absolute;
  top: 100%;
  right: 0;
  display: block;
  padding: 35px 15px 15px 35px;
  max-width: 980px;
  background: url(../images/team-name.svg) no-repeat;
  background-size: 100% 100%;
  font-family: "nimbus-sans-condensed", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 30px;
}

#topNav td > a {
  padding: 5px 3px;
  color: #FFF;
  font-weight: 900;
}

#topNav td:hover > a, #topNav td.hover > a {
  color: #ffcc00;
}

#topNav td.selected > a {
  background-color: #000;
  color: #fc0;
}

#topNav td.disabled > a {
  background-color: #b30000;
  color: #000;
}

#topNav td ul {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

#topNav td ul li + li {
  border-top: 1px solid #000;
}

#topNav td ul li a {
  padding: 10px 15px;
  min-width: 120px;
  color: #FFF;
  background: #222;
}

#topNav td ul li:hover > a, #topNav td ul li.hover > a {
  background: #cc0000;
  color: #fc0;
}

#topNav td ul li a.disabled {
  background: #666;
  color: #000;
}

.siteContainer:before {
  content: '';
  display: block;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -50vw;
  height: 152px;
  background: url(../images/chalkboard-bg.png) top left repeat;
  margin-top: -20px;
  padding: 10px 0 0 0;
  transform: rotate(1deg);
  box-shadow: inset 0px -40px 0px -30px rgba(0, 0, 0, 0.5);
}

.site-header {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 262px;
  height: 110px;
  z-index: 99;
  margin-left: 10px;
}

.site-header a {
  display: block;
  width: 100%;
  width: 100%;
  height: 110px;
  background: url(../images/header-logo.png);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 1270px) {
  #topNav tbody {
    max-width: 100%;
  }
  .site-header {
    top: 60px;
  }
  .siteContainer:before {
    height: 200px;
    transform: rotate(0deg);
  }
}

.mobileNav {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobileNav {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  #topNav {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .site {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #FFF;
  }
  .site .site-title {
    display: none;
  }
  .site:after {
    content: "";
    height: 38px;
    width: 32px;
    background-image: url("../images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    transform: translateX(-25px);
  }
}

.navButton {
  display: none;
  position: relative;
  overflow: hidden;
  margin: 0 0 0 10px;
  padding: 10px;
  width: 50px;
  height: 40px;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
}

@media screen and (max-width: 768px) {
  .navButton {
    display: block;
  }
}

.navButton:focus {
  outline: none;
}

.navButton span {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  right: 10px;
  height: 3px;
  background: #FFF;
}

.navButton span::before,
.navButton span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #FFF;
  content: "";
}

.navButton span::before {
  top: -9px;
}

.navButton span::after {
  bottom: -9px;
}

.navButton {
  background-color: #cc0000;
}

.navButton span {
  transition: background 0s 0.1s;
}

.navButton span::before,
.navButton span::after {
  transition-duration: 0.1s, 0.1s;
  transition-delay: 0.1s, 0s;
}

.navButton span::before {
  transition-property: top, transform;
}

.navButton span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.navButton.expanded span {
  background: none;
}

.navButton.expanded span::before {
  top: 0;
  transform: rotate(45deg);
}

.navButton.expanded span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.navButton.expanded span::before,
.navButton.expanded span::after {
  transition-delay: 0s, 0.1s;
}

/* Styling */
.mobileNav {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobileNav.expanded {
    display: block;
  }
}

.mobileNav li {
  text-align: left;
  background-color: #cc0000;
  border-bottom: 1px solid #990000;
  transition: color .2s linear, background-color .2s linear;
}

.mobileNav li a {
  color: #FFF;
  transition: color .2s linear, background-color .2s linear;
}

.mobileNav .dropdown.expanded {
  border-top: 1px solid #e60000;
}

.mobileNav li.expanded, .mobileNav li.expanded li.expanded {
  border: none;
}

.mobileNav .lastNavElement .dropdown > li.expanded:last-of-type {
  padding: 0;
}

.mobileNav .dropdown li {
  background-color: #222;
  border-bottom: none;
}

.mobileNav .dropdown li:last-of-type {
  border-bottom: 1px solid #e60000;
}

.mobileNav .dropdown li a {
  color: #FFF;
}

.mobileNav .dropdown li li {
  background-color: #FFF;
  border-bottom: none;
}

.mobileNav .dropdown li li a {
  color: #cc0000;
}

.mobileNav .dropdown li ul {
  border-top: 1px solid #000;
}

.mobileNav li:hover, .mobileNav li.selected {
  background-color: #990000;
}

.mobileNav li li:hover, .mobileNav li li.selected {
  background-color: #990000;
}

.mobileNav li li li:hover, .mobileNav li li li.selected {
  background-color: #FFF;
}

.mobileNav li a:hover, .mobileNav li a.selected {
  color: #FFF;
}

.mobileNav li li a:hover, .mobileNav li li a.selected {
  color: #fc0;
}

.mobileNav a, .mobileNav .nodeToggle {
  display: inline-block;
  font-family: "nimbus-sans-condensed", sans-serif;
  font-size: 1.14286rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  padding: 10px;
  text-decoration: none;
}

.mobileNav .dropdown a {
  display: inline-block;
  transition: color .2s linear;
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: none;
  letter-spacing: 0;
  padding: 10px 0 10px 20px;
}

.mobileNav .dropdown ul a {
  margin-left: 20px;
}

.mobileNav .sn-tag-logo, .mobileNav .site-logo {
  display: none;
}

.nodeToggle {
  cursor: pointer;
  display: inline-block;
  padding-left: 0;
}

.nodeToggle:before {
  border-color: #FFF;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 8px;
  left: 0;
  position: relative;
  top: 4px;
  transform: rotate(45deg);
  vertical-align: top;
  width: 8px;
  transition: transform .1s linear;
}

li li .nodeToggle:before {
  border-color: #FFF;
}

.nodeToggle.expanded:before {
  transform: rotate(135deg);
}

.nodeToggle + ul {
  display: none;
}

@media screen and (max-width: 768px) {
  .site-header {
    margin: 0;
    padding: 10px 10px 20px 10px;
    max-width: 100%;
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  .siteContainer:before {
    height: 150px;
  }
}

#panelOne .subNav {
  font-size: 14px;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

#panelOne .subNav h4 {
  font-family: "Hind Vadodara", sans-serif;
  font-weight: 400;
  font-size: 1em;
  display: inline-block;
  display: none;
  padding-top: 1px;
}

#panelOne .subNav h4:after {
  content: "\0399";
  display: inline-block;
  margin: 0 10px;
  font-family: "Arial", sans-serif;
  font-weight: 100;
}

#panelOne .subNav .parents {
  display: flex;
  flex-flow: row wrap;
  font-family: "Hind Vadodara", sans-serif;
  font-weight: 400;
  font-size: 1em;
}

.user_mode #panelOne .subNav .parents li + li {
  margin-left: 10px;
}

.user_mode #panelOne .subNav .parents li + li:before {
  content: "\203A";
  color: #999;
  display: inline-block;
  margin-right: 10px;
  transform: translateY(-1px);
  font-family: "Arial", sans-serif;
  font-weight: 100;
}

.user_mode #panelOne .subNav .parents li + li.selected a {
  color: #e60000;
}

#panelOne .subNav .parents li a {
  display: inline-block;
  color: #999;
  transition: color .2s linear;
  padding: 5px 0;
  font-size: inherit;
}

#panelOne .subNav .parents li a:hover {
  color: red;
}

#panelOne .subNav .parents .cmsNavButton {
  margin-left: 10px;
}

#panelOne .subNav .children {
  margin-top: 1em;
}

.user_mode #panelOne .subNav .children {
  display: none;
}

.user_mode .scroller-content {
  display: none;
}

.edit_mode .scroller-content .codeElement:before {
  content: "The following code is used it import an RSS feed into the scrolling text on the Home page. Copy and paste the code from a news aggregator's RSS feed here, to update the scrolling text.";
  text-align: center;
  color: #FFF;
  padding: 10px;
  max-width: 620px;
  margin: 0 auto;
  display: block;
}

.edit_mode .scroller-content .codeElement pre {
  background: #222;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
  text-align: center;
}

#pageContentContainer .tickerScroller {
  position: relative;
  margin: auto;
  width: 100%;
  height: 25px;
  overflow: hidden;
  border-radius: 5px;
}

#pageContentContainer .tickerScroller .scroller {
  position: absolute;
  width: 4000px;
  left: 0px;
}

#pageContentContainer .leftgradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
  position: absolute;
  z-index: 99;
  left: 0;
  height: 40px;
  width: 60px;
}

#pageContentContainer .rightgradient {
  background: linear-gradient(to right, transparent 4%, rgba(0, 0, 0, 0.11) 15%, rgba(0, 0, 0, 0.99) 99%, black 100%);
  position: absolute;
  z-index: 99;
  right: 0;
  height: 40px;
  width: 60px;
}

#pageContentContainer .tickerScroller .scroller a {
  position: relative;
  display: inline-block;
  float: left;
  color: #ffffff;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}

#pageContentContainer .tickerScroller .scroller a:hover {
  color: red;
}

#pageContentContainer .tickerScroller .scroller a h1 {
  text-indent: 0;
  font-size: 16px;
  line-height: 1.5em;
}

/* liScroll styles */
.tickercontainer {
  /* the outer div with the black border */
  border: 1px solid #000;
  background: #fff;
  width: 738px;
  height: 27px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.tickercontainer .mask {
  /* that serves as a mask. so you get a sort of padding both left and right */
  position: relative;
  left: 10px;
  top: 8px;
  width: 718px;
  overflow: hidden;
}

ul.newsticker {
  /* that's your list */
  position: relative;
  left: 750px;
  font: bold 10px Verdana;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.newsticker li {
  float: left;
  /* important: display inline gives incorrect results when you check for elem's width */
  margin: 0;
  padding: 0;
  background: #fff;
}

ul.newsticker a {
  white-space: nowrap;
  padding: 0;
  color: #ff0000;
  font: bold 10px Verdana;
  margin: 0 50px 0 0;
}

ul.newsticker span {
  margin: 0 10px 0 0;
}

.photo-slidehow {
  z-index: 1;
  position: relative;
}

.user_mode .photo-slidehow:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.photo-slidehow .prev_btn, .photo-slidehow .next_btn, .photo-slidehow .image_title, .photo-slidehow .controls {
  display: none !important;
}

.photo-slidehow .crop {
  padding-top: 28.80859% !important;
}

.photo-slidehow .galleryElement_gallery .image {
  width: 100% !important;
  height: auto !important;
  left: 0 !important;
}

.page-banner {
  z-index: -1;
}

.page-banner.banner-present {
  border-bottom: 5px solid #cc0000;
  margin-top: -70px;
}

@media screen and (max-width: 1270px) {
  .page-banner.banner-present {
    margin-top: -10px;
  }
}

@media screen and (max-width: 768px) {
  .page-banner.banner-present {
    margin-top: 0;
  }
}

.page-banner-img {
  width: 100%;
  display: block;
}

#page-banner-header {
  position: relative;
}

#page-banner-header span {
  display: block;
  background: #cc0000;
  color: #FFF;
  font-size: 44px;
  font-family: "nimbus-sans-condensed", sans-serif;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 5px 0;
  -webkit-font-smoothing: antialiased;
}

#page-banner-header span:empty {
  display: none;
}

@media screen and (min-width: 500px) {
  #page-banner-header span {
    display: inline-block;
    padding: 15px 15px 0;
    background: url(../images/news-page-header.svg);
    background-size: cover;
    margin-left: 15px;
    position: absolute;
    bottom: 0;
  }
}

#monthViewCalendar {
  margin: 0;
}

#monthViewCalendar table, #monthViewCalendar tbody, #monthViewCalendar th, #monthViewCalendar tr, #monthViewCalendar td {
  display: block;
  background: 0;
}

#monthViewCalendar tr {
  display: flex;
  flex-flow: row;
  align-items: stretch;
  flex: 0 0 auto;
  margin-right: -6px;
}

#monthViewCalendar th, #monthViewCalendar td {
  width: 14.285714286%;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 10px;
  background: #EFEFEF;
}

#monthViewCalendar th:before {
  font-size: 1rem;
  display: none;
}

@media screen and (max-width: 768px) {
  #monthViewCalendar th {
    font-size: 0;
  }
  #monthViewCalendar th:before {
    display: block;
  }
}

#monthViewCalendar th:nth-of-type(1):before {
  content: "Sun";
}

#monthViewCalendar th:nth-of-type(2):before {
  content: "Mon";
}

#monthViewCalendar th:nth-of-type(3):before {
  content: "Tue";
}

#monthViewCalendar th:nth-of-type(4):before {
  content: "Wed";
}

#monthViewCalendar th:nth-of-type(5):before {
  content: "Thu";
}

#monthViewCalendar th:nth-of-type(6):before {
  content: "Fri";
}

#monthViewCalendar th:nth-of-type(7):before {
  content: "Sat";
}

#monthViewCalendar td {
  min-height: 100px;
  height: auto;
  background: #FFF;
}

#monthViewCalendar caption {
  display: block;
  margin: 0 auto;
}

#monthViewCalendar .dateLink {
  text-decoration: none;
}

#monthViewCalendar .dateLink + .vevent {
  border-top: 1px solid #efefef;
}

#monthViewCalendar .today {
  background: #E4E4E4;
}

#monthViewCalendar .today a {
  color: #cc0000;
}

#monthViewCalendar .today .dateLink + .vevent {
  border-top: 1px solid #cc0000;
}

#monthViewCalendar .vevent {
  border: none;
  padding: 10px 0 0 0;
  margin: 10px 0;
}

@media screen and (max-width: 610px) {
  #monthViewCalendar tr {
    flex-flow: column;
  }
  #monthViewCalendar th {
    display: none;
  }
  #monthViewCalendar td {
    width: 100%;
    min-height: auto;
    text-align: left;
  }
  #monthViewCalendar .otherMonth {
    text-align: left;
  }
}

.linkElement h4 a {
  font-size: 16px;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  background: 0;
  padding: 6px 10px;
  background-color: #fc0;
  color: #000;
}

.linkElement h4 a:hover {
  background-color: #cc0000;
  color: #fc0;
}

.linkElement h4 a:after {
  content: " \203A";
}

.linkElement .text {
  margin: 0 0 10px 0;
  padding: 5px;
}

.alternate .linkElement h4 a {
  background-color: #cc0000;
  color: #FFF;
}

.alternate .linkElement h4 a:hover {
  background-color: #fc0;
  color: #000;
}

.newsItemColumn {
  padding-top: 0;
}

.newsItemColumn .newsPreviewThumb {
  width: auto;
  float: none;
  margin: 0;
  width: auto !important;
  max-width: 100%;
  max-height: 75vh;
}

.newsItemColumn .newsItemElement {
  border-bottom: 2px solid #EEE;
  margin-bottom: 20px !important;
  margin-right: 0 !important;
}

.newsItemColumn .newsItemElement h2 {
  margin: 20px 0;
}

.newsItemColumn .newsItemElement h2:first-child {
  margin-top: 0;
}

.newsItemColumn .newsItemElement h4 {
  margin: 0 0 20px 0;
}

.newsItemColumn .newsDate, .newsItemColumn .newsAuthor {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

.newsItemColumn .newsAuthor {
  margin: 10px 0 20px 0;
}

.newsTags {
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}

.siteMapElement {
  background: none;
  border: none;
  margin: 0 0 10px 0 !important;
  padding: 0;
}

.siteMapElement a {
  text-decoration: none;
}

.siteMapElement .unlimitedHierarchy {
  background: none;
  margin: 0;
  width: auto;
}

.siteMapElement .unlimitedHierarchy ul {
  margin-left: .75em;
  border-left: 1px solid #AAA;
  padding-bottom: 1em;
}

.siteMapElement .unlimitedHierarchy li {
  display: block;
  padding: 0 0 0 5px;
  clear: both;
  list-style: none;
  background: none;
}

.siteMapElement .unlimitedHierarchy li li {
  margin-left: 1.5em;
  padding: 0;
}

.siteMapElement .unlimitedHierarchy li div {
  padding: 5px;
  background: none;
}

.siteMapElement .unlimitedHierarchy .lastLi {
  background: none;
}

.siteMapElement .unlimitedHierarchy .lastLi > ul {
  padding-bottom: 0;
  margin-bottom: 1em;
}

#news .newsAggregatorElement .item {
  background-color: #cc0000;
  background-size: 100% 100%;
  color: #FFF;
  padding: 25px;
  position: relative;
  z-index: 0;
}

#news .newsAggregatorElement .item:before {
  content: "";
  width: 105%;
  height: 30px;
  background: url(../images/poly-lb.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  left: 0;
  top: -30px;
  z-index: 0;
}

.edit_mode #news .newsAggregatorElement .item:before, #news .newsAggregatorElement .edit_mode .item:before {
  display: none;
}

#news .newsAggregatorElement .item:after {
  content: "";
  width: 100%;
  height: 20px;
  background: url(../images/poly-lt.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: -1;
}

.edit_mode #news .newsAggregatorElement .item:after, #news .newsAggregatorElement .edit_mode .item:after {
  display: none;
}

#news .newsAggregatorElement .item > div {
  margin: 0;
  padding: 0;
}

#news .newsAggregatorElement .item > div:before {
  content: "";
  width: 5%;
  height: 100%;
  background: url(../images/poly-lt.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  right: -5%;
  z-index: 0;
}

.edit_mode #news .newsAggregatorElement .item > div:before, #news .newsAggregatorElement .edit_mode .item > div:before {
  display: none;
}

#news .newsAggregatorElement {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
  justify-content: space-around;
  margin: 0;
}

#news .newsAggregatorElement .item {
  background: #cc0000;
  padding: 15px;
  margin: 30px 0 40px 0;
  flex-basis: 30%;
}

#news .newsAggregatorElement .item:before {
  z-index: -1;
}

@media screen and (max-width: 768px) {
  #news .newsAggregatorElement .item {
    flex-basis: 45%;
  }
}

@media screen and (max-width: 490px) {
  #news .newsAggregatorElement .item {
    flex-basis: 100%;
  }
  #news .newsAggregatorElement .item:after, #news .newsAggregatorElement .item > div:before {
    display: none;
  }
}

#news .newsAggregatorElement .newsItemElement {
  margin-top: -30px !important;
}

#news .newsAggregatorElement .newsItemHeader {
  display: flex;
  flex-flow: column;
}

#news .newsAggregatorElement .newsItemHeader > a {
  order: 1;
  display: block;
  background-size: cover;
  background-position: top center;
  padding-bottom: 60%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  margin-bottom: 1em;
}

#news .newsAggregatorElement .newsItemHeader > a img {
  display: none;
  float: none;
  width: 100%;
  transform: none;
}

#news .newsAggregatorElement .newsItemHeader .newsDate {
  order: 2;
  margin-bottom: 10px;
}

#news .newsAggregatorElement .newsItemHeader h2 {
  order: 3;
}

#news .newsAggregatorElement .newsItemHeader h2 a {
  display: block;
  line-height: 1.2;
  color: #FFF;
}

#news .newsAggregatorElement .newsItemHeader h2 a:hover {
  color: #fc0;
}

#news .newsAggregatorElement .newsText, #news .newsAggregatorElement .newsPreviewText, #news .newsAggregatorElement .newsItemElement h4, #news .newsAggregatorElement .commentsAndMore, #news .newsAggregatorElement .extendedOptions, #news .newsAggregatorElement .newsAuthor {
  display: none;
}

.newsAggregatorElement .draftNotice {
  font-size: 12px;
}

.newsAggregatorElement .even, .newsAggregatorElement .odd {
  background-color: transparent;
}

.newsAggregatorElement a {
  text-decoration: none;
}

.newsAggregatorElement .item {
  line-height: 1.2;
  border: none;
  padding: 0 0 10px 0;
}

.newsAggregatorElement .item + .item {
  padding-top: 10px;
}

.newsAggregatorElement .item.odd {
  background-color: transparent;
}

.newsAggregatorElement .item.condensed {
  position: relative;
  display: flex;
}

.newsAggregatorElement .item.condensed .expandedView {
  display: none;
}

.newsAggregatorElement .item.condensed > a {
  display: block;
  width: auto;
  position: absolute;
  overflow: hidden;
}

.newsAggregatorElement .condensed h4 {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.newsAggregatorElement .condensed h4 a {
  color: #000;
}

.newsAggregatorElement .condensed .dateAuthor {
  font-family: "Arial", sans-serif;
  font-size: 0.92857rem;
  line-height: 1;
  color: #fc0;
}

.newsAggregatorElement .condensed .teaser.expandedView {
  display: block;
  padding-top: 10px;
  font-size: 0.85714rem;
  line-height: 1.5;
}

.newsAggregatorElement .expanded .newsItemElement h2 {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}

.newsAggregatorElement .expanded .newsItemElement h2 a {
  color: #000;
}

.newsAggregatorElement .expanded .newsItemElement h2 a:hover {
  text-decoration: none;
}

.newsAggregatorElement .expanded .newsDate, .newsAggregatorElement .expanded .newsAuthor {
  font-family: "Arial", sans-serif;
  font-size: 0.92857rem;
  line-height: 1;
  color: #fc0;
}

.newsAggregatorElement .expanded .teaser.expandedView {
  display: block;
  padding-top: 10px;
  font-size: 0.85714rem;
  line-height: 1.5;
}

.newsAggregatorElement .details {
  padding-left: 80px;
}

.newsAggregatorElement a img {
  width: 65px;
  transform: translateY(5px);
}

.aggregatorElement .extendedOptions {
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  margin: 0;
  padding: 10px 0;
  border: none !important;
  background-color: transparent;
}

.aggregatorElement .extendedOptions a {
  text-decoration: none;
}

.aggregatorElement .extendedOptions .icon {
  padding: 0;
  background: none;
  padding: 0 2px;
  color: #999;
  transition: .2s linear;
}

.aggregatorElement .extendedOptions .icon:hover {
  color: #cc0000;
}

.eventAggregatorElement .even, .eventAggregatorElement .odd {
  background-color: transparent;
}

.eventAggregatorElement a {
  text-decoration: none;
}

.eventAggregatorElement .vevent {
  line-height: 1.2;
  border: none;
  border-bottom: 2px solid #EEE;
  padding: 0 0 10px 0;
  min-height: 85px;
}

.eventAggregatorElement .vevent + .vevent {
  padding-top: 10px;
  min-height: 85px;
}

.eventAggregatorElement .vevent.odd {
  background-color: transparent;
}

.eventAggregatorElement .vevent .dateImage {
  width: 70px;
  padding-left: 5px;
}

.eventAggregatorElement .vevent .dateImage .month {
  font-size: 1.28571rem;
  font-weight: 600;
  line-height: 1.5;
  color: #999;
  text-transform: uppercase;
}

.eventAggregatorElement .vevent .dateImage .date {
  font-size: 3.57143rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #222;
  letter-spacing: -3px;
}

.eventAggregatorElement .vevent .summary, .eventAggregatorElement .vevent .details {
  padding-left: 80px;
}

.eventAggregatorElement .vevent .summary {
  font-family: "Arial", sans-serif;
  font-size: 1.28571rem;
  font-weight: 400;
  line-height: 1.5;
}

.eventAggregatorElement .vevent .summary a {
  color: #e60000;
}

.eventAggregatorElement .vevent .details {
  padding-top: 10px;
}

.eventAggregatorElement .vevent .time, .eventAggregatorElement .vevent .location, .eventAggregatorElement .vevent .location:before {
  font-family: "Arial", sans-serif;
  font-size: 0.85714rem;
  font-style: italic;
  line-height: 1;
}

.eventAggregatorElement .vevent .time a {
  display: none;
}

.eventAggregatorElement .vevent .location:before {
  content: "/ ";
}

.eventAggregatorElement .tags, .eventAggregatorElement .description {
  display: none;
}

.eventAggregatorElement .condensed h4 {
  font-family: "Arial", sans-serif;
  font-size: 1.28571rem;
  line-height: 1.5;
}

.eventAggregatorElement .condensed h4 a {
  color: #e60000;
}

.eventAggregatorElement .condensed .dateAuthor {
  font-family: "Arial", sans-serif;
  font-size: 0.85714rem;
  font-style: italic;
  line-height: 1;
  padding-top: 10px;
}

.eventAggregatorElement .expanded .newsItemElement h2 {
  font-family: "Arial", sans-serif;
  font-size: 1.28571rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.eventAggregatorElement .expanded .newsItemElement h2 a {
  color: #e60000;
}

.eventAggregatorElement .expanded .newsItemElement h2 a:hover {
  text-decoration: none;
}

.eventAggregatorElement .expanded .newsDate, .eventAggregatorElement .expanded .newsAuthor {
  font-family: "Arial", sans-serif;
  font-size: 0.85714rem;
  font-style: italic;
  line-height: 1;
  padding-top: 10px;
}

@media screen and (max-width: 290px) {
  .column .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 0;
    position: relative;
    margin-bottom: 1em;
  }
  .column .eventAggregatorElement .vevent .summary, .column .eventAggregatorElement .vevent .details {
    padding-left: 80px;
    padding: 0;
  }
}

@media screen and (min-width: 651px) and (max-width: 770px) {
  .pe33 .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 0;
    position: relative;
    margin-bottom: 1em;
  }
  .pe33 .eventAggregatorElement .vevent .summary, .pe33 .eventAggregatorElement .vevent .details {
    padding-left: 80px;
    padding: 0;
  }
}

@media screen and (min-width: 651px) {
  .threeColumn255025 .pe25 .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 0;
    position: relative;
    margin-bottom: 1em;
  }
  .threeColumn255025 .pe25 .eventAggregatorElement .vevent .summary, .threeColumn255025 .pe25 .eventAggregatorElement .vevent .details {
    padding-left: 80px;
    padding: 0;
  }
}

@media screen and (min-width: 450px) and (max-width: 575px), (min-width: 769px) and (max-width: 1009px) {
  .threeColumn502525 .pe25 .eventAggregatorElement .vevent .dateImage, .threeColumn252550 .pe25 .eventAggregatorElement .vevent .dateImage {
    width: 100%;
    padding: 0;
    position: relative;
    margin-bottom: 1em;
  }
  .threeColumn502525 .pe25 .eventAggregatorElement .vevent .summary, .threeColumn502525 .pe25 .eventAggregatorElement .vevent .details, .threeColumn252550 .pe25 .eventAggregatorElement .vevent .summary, .threeColumn252550 .pe25 .eventAggregatorElement .vevent .details {
    padding-left: 80px;
    padding: 0;
  }
}

.aggregatorElement table {
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

.aggregatorElement .eventPreview p {
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

.aggregatorElement .eventPreview {
  text-align: left;
  width: 100%;
  margin: 0;
}

.aggregatorElement .eventPreview th {
  background: #DDD;
  word-break: break-word;
  text-align: center;
  line-height: 1;
}

.aggregatorElement .eventPreview th a {
  color: #990000;
  font-weight: 600;
}

.aggregatorElement .eventPreview td {
  padding: 10px;
  background-image: none;
  background: transparent;
  word-break: break-word;
}

.aggregatorElement .eventPreview td:before {
  content: attr(data-week-view-date) "";
  display: none;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid #999;
  background: #DDD;
  color: #990000;
  font-family: "Arial", sans-serif;
  font-size: 1em;
  line-height: 1;
  font-weight: 600;
}

.aggregatorElement .eventPreview td a {
  font-family: "Arial", sans-serif;
}

.aggregatorElement .eventPreview td,
.aggregatorElement .eventPreview th {
  border: none;
  border: 1px solid #999;
}

.aggregatorElement .eventPreview .event + .event {
  padding-top: 10px;
}

.aggregatorElement .eventPreview .noentry {
  word-break: normal;
}

.noEntry {
  border: none;
  background-color: transparent;
  font-size: 12px;
}

.aggregatorElement .eventPreview .event {
  border: none;
  padding: 0;
}

@media screen and (max-width: 450px) {
  .column .aggregatorElement .eventPreview,
  .column .aggregatorElement .eventPreview tbody,
  .column .aggregatorElement .eventPreview tr,
  .column .aggregatorElement .eventPreview td {
    display: block;
  }
  .column .aggregatorElement .eventPreview thead {
    display: none;
  }
  .column .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .column .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .column .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .column .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .column .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 651px) {
  .pe33 .aggregatorElement .eventPreview,
  .pe33 .aggregatorElement .eventPreview tbody,
  .pe33 .aggregatorElement .eventPreview tr,
  .pe33 .aggregatorElement .eventPreview td {
    display: block;
  }
  .pe33 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .pe33 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .pe33 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .pe33 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .pe33 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .pe33 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 651px) {
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview,
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview tbody,
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview tr,
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview td {
    display: block;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .threeColumn255025 .pe25 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 451px) {
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview,
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview tbody,
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview tr,
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview td, .threeColumn252550 .pe25 .aggregatorElement .eventPreview,
  .threeColumn252550 .pe25 .aggregatorElement .eventPreview tbody,
  .threeColumn252550 .pe25 .aggregatorElement .eventPreview tr,
  .threeColumn252550 .pe25 .aggregatorElement .eventPreview td {
    display: block;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview thead, .threeColumn252550 .pe25 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview td, .threeColumn252550 .pe25 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview td:before, .threeColumn252550 .pe25 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview .event, .threeColumn252550 .pe25 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview .event + .event, .threeColumn252550 .pe25 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .threeColumn502525 .pe25 .aggregatorElement .eventPreview tbody, .threeColumn252550 .pe25 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

@media screen and (min-width: 651px) and (max-width: 850px) {
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview,
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview tbody,
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview tr,
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview td, .twoColumn5050 .pe50 .aggregatorElement .eventPreview,
  .twoColumn5050 .pe50 .aggregatorElement .eventPreview tbody,
  .twoColumn5050 .pe50 .aggregatorElement .eventPreview tr,
  .twoColumn5050 .pe50 .aggregatorElement .eventPreview td {
    display: block;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview thead, .twoColumn5050 .pe50 .aggregatorElement .eventPreview thead {
    display: none;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview td, .twoColumn5050 .pe50 .aggregatorElement .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 1px;
    word-break: word-break;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview td:before, .twoColumn5050 .pe50 .aggregatorElement .eventPreview td:before {
    display: block;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview .event, .twoColumn5050 .pe50 .aggregatorElement .eventPreview .event {
    padding: 10px;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview .event + .event, .twoColumn5050 .pe50 .aggregatorElement .eventPreview .event + .event {
    padding-top: 0;
  }
  .threeColumn255025 .pe50 .aggregatorElement .eventPreview tbody, .twoColumn5050 .pe50 .aggregatorElement .eventPreview tbody {
    border-bottom: 1px solid #999;
  }
}

/*
  Responsive Sport Header
  version: 1.0
  last modified: 11.3.2015 by Daniel Henderson
  author: Daniel Henderson
  email: dan.henderson@sportngin.com
  dependencies:
    1. Font Awesome https://fortawesome.github.io/Font-Awesome/
    2. sport_header.js
*/
@-webkit-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@-moz-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@-o-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

#sport-header {
  float: left;
  display: block;
  width: 100%;
}

#sport-header .teamLogo {
  left: 15px;
  height: 40px;
  width: 120px;
}

#sport-header.noDrop #megaDropDown-seasons {
  padding-right: 10px !important;
}

#sport-header #snm_advert {
  display: inline-block;
  text-decoration: none;
  padding: 10px;
  background: transparent;
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#sport-header #snm_advert:before {
  width: 30px;
  height: 25px;
  position: absolute;
  display: block;
  font: normal 14px/1em "Arial", sans-serif;
  font-size: 1.75em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  background: url("http://app-assets2.sportngin.com/app_images/marketing/mobilePhone.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#sport-header #snm_advert:hover {
  opacity: 1;
}

#sport-header #snm_advert span, #sport-header #snm_advert strong {
  color: #444;
  font-size: 9px;
  text-align: left;
  display: block;
  line-height: 13px;
  display: block;
  padding-left: 33px;
}

#sport-header .sport-tools {
  margin: 10px 0px 20px 0px;
  width: 100%;
  display: block;
  background: none;
  border-top: 1px solid #efefef;
  float: left;
  padding: 0px;
}

#sport-header .sport-tools ul {
  width: 100%;
  z-index: 1;
  position: relative;
}

#sport-header .sport-tools ul:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: #efefef;
  float: left;
  z-index: -1;
  position: absolute;
  opacity: 1;
  visibility: visible;
  bottom: 0px;
}

#sport-header .sport-tools ul li {
  display: inline-block;
  margin: auto;
  background: none;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 3px solid #eee;
  float: left;
  list-style: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

#sport-header .sport-tools ul li a {
  display: inline-block;
  text-align: center;
  padding: 10px;
  background: none;
  border: none;
  list-style: none;
  cursor: pointer;
  color: #777;
  display: block;
  font: 1em/1.3em "Arial", sans-serif;
  outline: medium none;
  text-decoration: none;
  text-shadow: 1px 1px 1px #fff;
  width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

#sport-header .sport-tools ul li a:before {
  font: normal normal normal 14px/1em FontAwesome;
  padding: 0px;
  display: none;
}

#sport-header .sport-tools ul li:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 3px solid #cc0000;
}

#sport-header .sport-tools ul li:hover a {
  color: #000;
}

#sport-header .sport-tools ul .selected {
  border-bottom: 3px solid #cc0000;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
}

#sport-header .sport-tools ul .selected:hover a {
  color: #cc0000;
}

#sport-header .sport-tools ul .selected a {
  color: #cc0000;
}

#sport-header .sport-tools ul #tool-home a span {
  background: none;
  text-indent: 0px;
  width: auto;
  height: auto;
}

#sport-header .sport-tools ul #tool-home a:before {
  content: "\f015";
}

#sport-header .sport-tools ul #tool-roster a:before {
  content: "\f007";
}

#sport-header .sport-tools ul #tool-game-schedule a:before {
  content: "\f133";
}

#sport-header .sport-tools ul #tool-player-stats a:before {
  content: "\f080";
}

#sport-header .sport-tools ul #tool-team-stats a:before {
  content: "\f1fe";
}

#sport-header .sport-tools ul #tool-standings a:before {
  content: "\f1b3";
}

#sport-header .sport-tools ul #tool-bracket a:before {
  content: "\f0e8";
}

#sport-header .sport-tools ul #tool-photo a:before {
  content: "\f03e";
}

#sport-header .sport-tools ul #tool-video a:before {
  content: "\f1c8";
}

#sport-header .sport-tools ul #tool-posts a:before {
  content: "\f086";
}

#sport-header .sport-tools ul .tool-yellow-admin a:before {
  content: "\f013";
}

#sport-header .sport-tools ul .tool-yellow a:before {
  content: "\f013";
}

#sport-header .sport-tools ul .tool-yellow-manage-team a:before {
  content: "\f024";
}

#sport-header .sport-tools ul.tools {
  width: 100%;
}

#sport-header .sport-tools .button-tool {
  float: left;
}

#sport-header .sport-tools li, #sport-header .sport-tools #autoRefreshBar li {
  display: inline;
  float: left;
  border-right: 1px solid #eee;
}

#sport-header .sport-tools li.tool-yellow {
  border-color: #edc52c;
}

#sport-header .sport-tools li.tool-yellow a {
  background: #fddd40 url("http://app-assets2.sportngin.com/app_images/sport-header/btnsBG-yl.gif") 0 0 repeat-x;
  border-color: #ffc;
  color: #807540;
}

#sport-header .sport-tools li.tool-yellow:hover {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:hover a {
  background-color: #fddd40;
  background-position: 0 -80px;
  border-color: #ffc;
  color: #1a1a1a;
}

#sport-header .sport-tools li.tool-yellow:active {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:active a {
  background-color: #fddd40;
  background-position: 0 -160px;
  color: #333;
}

#sport-crumbs {
  background: #fff !important;
  position: relative;
  float: left;
  display: block;
  width: 100%;
}

#sport-crumbs .sport-crumbs-btns {
  display: block;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

#sport-crumbs .sport-crumbs-btns::-webkit-scrollbar {
  display: none;
}

#sport-crumbs ul {
  display: inline-block;
  float: left;
}

#sport-crumbs ul li {
  float: left;
  list-style: none;
}

#sport-crumbs ul li a {
  padding: 0px;
}

#sport-crumbs ul li a:before {
  content: "\f054";
  color: #ccc;
  display: inline-block;
  font: normal 10px/1em FontAwesome;
  padding: 2px;
  position: relative;
  margin: 0 0 0 10px;
}

#sport-crumbs ul li:first-child a:before {
  display: none;
}

#sport-crumbs ul li.crumbSelected {
  position: relative;
}

#sport-crumbs ul li.crumbSelected a {
  color: #000;
}

#sport-crumbs ul li.crumbSelected .arrow-down:after {
  content: "\f077";
  color: #000;
}

#sport-crumbs .sport-crumbs-list ul li a {
  padding: 10px 20px;
}

#sport-crumbs a {
  color: #666666;
  display: block;
  outline: medium none;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  font-weight: normal;
  background: none;
}

#sport-crumbs a.last_child {
  background: none;
}

#sport-crumbs a.last_child:hover {
  background: none;
}

#sport-crumbs a span {
  display: inline-block;
  font-size: 0.85em;
  padding: 10px;
  text-align: center;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#sport-crumbs a span:after {
  border: 1px solid #777;
  border-radius: 20px;
  font: 12px/1em FontAwesome;
  margin: auto;
  padding: 4px;
  text-align: center;
}

#sport-crumbs a span.division, #sport-crumbs a span.league, #sport-crumbs a span.team {
  background: none;
  padding: 5px 0 5px 5px;
}

#sport-crumbs a .arrow-down {
  background: none;
  display: block;
  height: auto;
  margin: auto;
  opacity: 0.75;
  padding: 0;
  position: relative;
  right: 0px;
  top: 50%;
  width: auto;
  display: inline-block;
  padding-left: 10px;
}

#sport-crumbs a:hover {
  color: #000;
  background: none;
}

#sport-crumbs a.wide {
  padding: 0px;
}

#sport-crumbs .arrow-down:after {
  content: "\f078";
  display: inline-block;
}

#sport-crumbs .sport-crumbs-list-wrapper {
  display: block;
  width: 100%;
}

#sport-crumbs .sport-crumbs-list {
  display: none;
  text-align: left;
  float: left;
  margin: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  max-width: 50%;
  z-index: 20;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

#sport-crumbs .sport-crumbs-list.thisCrumb {
  display: block !important;
  -o-animation: dropdown-season 0.2s linear;
  -moz-animation: dropdown-season 0.2s linear;
  -webkit-animation: dropdown-season 0.2s linear;
  animation: dropdown-season 0.2s linear;
}

#sport-crumbs .sport-crumbs-list ul {
  list-style: none;
  width: 100%;
}

#sport-crumbs .sport-crumbs-list ul li {
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a {
  background: #fff;
  color: #666;
  border-bottom: 1px solid #eee;
  display: block;
  font-size: 12px;
  line-height: 24px;
  padding: 5px 10px;
  text-decoration: none;
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a:before {
  display: none;
}

#sport-crumbs .sport-crumbs-list ul li a img {
  margin: 0 3px -3px 0;
}

#sport-crumbs .sport-crumbs-list ul li a:hover {
  color: #000;
  background-color: #fafafa;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close {
  display: block;
  background: #fff;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a {
  display: block;
  float: right;
  text-align: right;
  width: 50px;
  border-bottom: 0px;
  background: transparent;
  padding: 15px 15px 10px 10px;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after {
  content: "\f00d";
  position: relative;
  font: normal normal normal 12px/1em FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #666;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 40px;
  padding: 3px 5px;
  border: 1px solid #666;
  color: #666;
  opacity: 0.7;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after:hover {
  color: #000;
  border-color: #000;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:hover {
  background: #fff;
  color: #000;
}

.sport-colors {
  clear: both;
  overflow: visible;
  position: relative;
  float: left;
  width: 100%;
  border-bottom: 1px solid #fff;
}

.sport-colors .edge {
  display: none;
}

.sport-colors h2 {
  float: left;
  font: bold 2.3em/1.5em "Arial", sans-serif;
  max-width: 75%;
  padding: 15px 0px 15px 0px;
  position: relative;
  text-shadow: 1px 1px 0 #fff;
  width: 100%;
  left: 0;
  display: inline-block;
  width: 100%;
  top: 0;
  left: 0;
}

.sport-colors h2 a {
  display: inline-block;
  text-decoration: none;
  padding: 0px;
}

.sport-colors .teamLogo-large {
  height: 75px !important;
  width: 75px !important;
  position: relative;
  display: inline-block;
  float: left;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 3px solid #ddd;
  left: 0;
  top: 0;
  margin: 15px;
  background-size: contain;
}

/* Seasons Mega Drop Down */
.dropdown-wrapper {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  z-index: 2;
}

.dropdown-wrapper.mdd-active-open #megaDropDown-seasons:after {
  content: "\f077";
}

.dropdown-wrapper.mdd-active-open #megaDropDown-seasons-callout {
  display: block;
  -o-animation: dropdown-season 0.2s linear;
  -moz-animation: dropdown-season 0.2s linear;
  -webkit-animation: dropdown-season 0.2s linear;
  animation: dropdown-season 0.2s linear;
}

.dropdown-wrapper #megaDropDown-seasons {
  background: #efefef;
  display: block;
  margin: auto;
  outline: medium none;
  text-align: right;
  text-decoration: none;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 50px 10px 10px !important;
  background-image: none !important;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.dropdown-wrapper #megaDropDown-seasons .season {
  color: #999;
  display: block;
  font: normal 10px/1em "Arial", sans-serif;
  margin: auto;
  width: auto;
}

.dropdown-wrapper #megaDropDown-seasons .subseason {
  color: #666;
  font: bold 12px/1em "Arial", sans-serif;
}

.dropdown-wrapper #megaDropDown-seasons:after {
  content: "\f078";
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 100px;
  border: 1px solid #666;
  color: #666;
  font: normal 12px/1em FontAwesome;
  padding: 4px;
}

.dropdown-wrapper #megaDropDown-seasons-callout {
  background: #fff;
  padding: 10px;
  text-align: left;
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 230px;
  z-index: 2;
  padding: 15px 15px 20px 15px;
  display: none;
  border: 2px solid #eee;
}

.dropdown-wrapper #megaDropDown-seasons-callout .season-title {
  display: block;
  font: bold 12px "Arial", sans-serif;
  padding: 0 0 5px;
  margin: 5px 0;
}

.dropdown-wrapper #megaDropDown-seasons-callout .season-title span {
  float: left;
}

.dropdown-wrapper #megaDropDown-seasons-callout select {
  width: 100%;
  border: 0px;
  border: 2px solid #eee;
  background: #fff;
  color: #000;
  background-image: url("http://app-assets2.sportngin.com/app_images/select-down-arrow.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 27px;
  padding: 7px;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dropdown-wrapper #megaDropDown-seasons-callout a {
  display: block;
  font-size: 12px;
  padding: 2px 5px;
  text-decoration: none;
  background-color: #efefef;
  text-align: center;
  color: #666;
  border: 1px solid #fff;
}

.dropdown-wrapper #megaDropDown-seasons-callout a:hover {
  background: #efefef;
  border: 1px solid #e6e6e6;
}

.dropdown-wrapper #megaDropDown-seasons-callout a.close {
  background: #fff;
  border: 1px solid #777;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #777;
  display: block;
  float: right;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 0px 7px 2px 7px;
}

.dropdown-wrapper #megaDropDown-seasons-callout a.current {
  background: #efefef none repeat scroll 0 0;
  border-radius: 0;
  color: #333;
  font-size: 11px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
}

.dropdown-wrapper #megaDropDown-seasons-callout .footer {
  margin: 10px 0 0;
}

.dropdown-wrapper #megaDropDown-seasons-callout optgroup option {
  margin: 0;
  padding: 0 0 0 10px;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active {
  cursor: pointer;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active:after {
  display: block;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active:hover {
  background-color: #efefef;
}

.teamPage #rapidTabs.rapidTabsConstruction {
  background: none;
}

.yieldPageContent {
  clear: both;
}

@media only screen and (max-width: 1025px) {
  #sport-header .sport-tools ul li a {
    font-size: .9em;
  }
}

@media only screen and (max-width: 769px) {
  #sport-header #sport-crumbs ul li.crumbSelected {
    position: static;
  }
  #sport-header #sport-crumbs .sport-crumbs-list {
    max-width: 100%;
  }
  #sport-header #sport-crumbs a span {
    font-size: 0.70em;
  }
  #sport-header #sport-crumbs a span:after {
    font: 10px/1em FontAwesome;
  }
  #sport-header #sport-crumbs a .arrow-down {
    margin-left: 0px;
  }
  #sport-header .sport-tools ul li a {
    padding: 10px 2px;
    font-size: 0em;
  }
  #sport-header .sport-tools ul li a:before {
    display: block;
  }
  /* Seasons Mega Drop Down */
  .dropdown-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .dropdown-wrapper:before {
    display: none;
  }
  .dropdown-wrapper #megaDropDown-seasons .season {
    font-size: 10px;
  }
  .dropdown-wrapper #megaDropDown-seasons .subseason {
    font-size: 9px;
  }
  .dropdown-wrapper #megaDropDown-seasons-callout {
    max-width: 100%;
  }
}

.abs0 {
  display: none;
}

.sport-colors .teamLogo-large {
  border: none;
  border-radius: none;
  min-width: 75px;
}

@media screen and (max-width: 300px) {
  .sport-colors .teamLogo-large {
    display: none;
  }
}

.sport-colors {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  float: none;
}

.sport-colors h2 {
  margin-right: auto;
}

.widgetHierarchy li {
  background: none;
  border: none;
}

.statTable {
  font-size: 16px;
  font-family: "nimbus-sans-condensed", sans-serif;
}

.statTable .odd {
  background-color: transparent;
}

.statTable tbody {
  background: #FFF;
}

.statTable tbody tr {
  border-bottom: 2px solid #000;
}

.statTable tr th {
  background: #333;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.statTable .ajax_sort:hover {
  color: #cc0000;
}

.statTable td, .statTable th {
  font-size: 16px;
  line-height: 1;
  padding: 10px;
  border: none;
}

.statTable .hightlight {
  background: #999;
}

.statTable .teamName, .statTable .teamName-tiny {
  font-weight: 300;
}

.row-4 .twitterFeedElement {
  margin: 0;
}

.row-4 .tweet-container {
  background-color: transparent;
  border: 0;
  color: #FFF;
}

.row-4 .tweet-header {
  padding: 0 0 20px 0;
}

.row-4 .tweet-header:before {
  content: "Latest Tweets";
  display: block;
  font-family: "nimbus-sans-condensed", sans-serif;
  font-weight: 900;
  color: #FFF;
  font-size: 2.57143rem;
  line-height: 1.2;
}

.row-4 .tweet-header .header {
  text-align: left;
}

.row-4 .tweet-header .header a {
  color: #fc0;
}

.row-4 .tweet-header .header a:hover {
  color: #FFF;
}

.row-4 .tweet-avatar, .row-4 .header-name {
  display: none;
}

.row-4 .header-screen-name {
  display: inline-block;
}

.row-4 .header-screen-name:before {
  content: "Follow us @";
  position: relative;
  border: 0;
  width: auto;
  top: auto;
  display: inline-block;
  margin: 0;
}

.row-4 .tweets {
  border: 0;
  background-color: transparent;
  max-height: initial;
}

.row-4 .tweet {
  border: 0;
  padding: 0 0 20px 55px;
  background: url(../images/twitter.svg);
  background-repeat: no-repeat;
  background-size: 50px;
  background-position-y: 5px;
}

.row-4 .tweet-text {
  padding: 0;
  font-size: 14px;
}

.row-4 .tweet-container-footer {
  display: none;
}

.row-4 .tweet-footer {
  background-color: transparent;
  padding: 0;
}

.row-4 .tweet-date, .row-4 .tweet-screen-name {
  display: none;
}

.row-4 .tweet-name:hover, .row-4 .tweet-hover:hover, .row-4 .tweet-screen-name, .row-4 .tweet-date, .row-4 .tweet-text a, .row-4 .tweet-text a:hover, .row-4 .tweet-date:hover, .row-4 .tweet-url, .row-4 .tweet-url:hover {
  color: #999;
}

.row-4 .tweet-icons {
  font-size: 13px;
}

.full-width {
  margin-right: 0;
}

.full-width-container {
  margin-right: 0;
  padding: 3vw;
}

.full-width-container.oneColumn .pageElement {
  margin-right: 0;
}

.user_mode .background-image {
  display: none;
}

.edit_mode .background-image:before {
  max-width: 300px;
  margin: 0 auto;
  display: block;
  padding: 1em;
  text-align: center;
  content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
}

.edit_mode .background-image img {
  max-width: 300px;
  margin: 0 auto;
  border: 2px solid #FFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.about-us {
  position: relative;
}

.about-us .linkElement h4 {
  margin-bottom: 30px;
}

.about-us .linkElement h4 a {
  background: #fc0;
  display: block;
  position: absolute;
  top: 0;
  left: 75%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  color: #000;
  text-decoration: none;
  font-family: "nimbus-sans-condensed", sans-serif;
  font-size: 52px;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  z-index: 99;
  white-space: nowrap;
}

.edit_mode .about-us .linkElement h4 a {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
}

.about-us .linkElement h4 a:after {
  content: "\00bb";
  font-family: fontawesome;
}

@media screen and (max-width: 650px) {
  .about-us .linkElement h4 a {
    white-space: initial;
  }
}

.about-us .linkElement h4 a:hover {
  background: #cc0000;
  color: #fc0;
}

.row-2 {
  background: url(../images/two-tone.svg), #FFF;
  background-size: cover;
  background-repeat: no-repeat;
}

.row-2 .fw-content {
  padding: 3em 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.row-2 .fw-content .column + .column {
  margin-left: 5em;
}

.red {
  background-color: #cc0000;
  background-size: 100% 100%;
  color: #FFF;
  padding: 25px;
  position: relative;
  z-index: 0;
}

.red:before {
  content: "";
  width: 105%;
  height: 30px;
  background: url(../images/poly-lb.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  left: 0;
  top: -30px;
  z-index: 0;
}

.edit_mode .red:before {
  display: none;
}

.red:after {
  content: "";
  width: 100%;
  height: 20px;
  background: url(../images/poly-lt.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: -1;
}

.edit_mode .red:after {
  display: none;
}

.red .textBlockElement {
  margin: 0;
  padding: 0;
}

.red .textBlockElement:before {
  content: "";
  width: 5%;
  height: 100%;
  background: url(../images/poly-lt.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  right: -5%;
  z-index: 0;
}

.edit_mode .red .textBlockElement:before {
  display: none;
}

.extend-br {
  transform: translate(40px, 40px);
  margin-top: -41px;
}

.home-aggregator {
  border: 10px solid #cc0000;
  padding: 25px;
  background-color: #FFF;
}

#siteContainer2.one_panel #panelTwo,
#siteContainer2.one_panel #yieldContent {
  background: none;
}

.row-3 {
  background-color: #FFF;
}

.user_mode .row-3 {
  padding-bottom: 80px;
}

.row-3 .fw-content {
  display: flex;
  flex-flow: row wrap;
}

.row-3 .fw-content .column1 {
  flex: 2 0 auto;
  align-self: center;
}

.row-3 .fw-content .column2 {
  flex: 1 0 auto;
  align-self: flex-end;
  margin-top: 3em;
}

.row-3 .heroPhotoElement img {
  max-width: 390px;
  margin: 0 auto;
}

.user_mode .row-3 .heroPhotoElement img {
  margin-bottom: -70px;
}

.row-3 .textBlockElement h3 span {
  font-family: "Arial", sans-serif;
  font-weight: 500;
  font-size: 26px;
  color: #cc0000;
}

.row-4 {
  background: url(../images/brushed-metal.png);
  background-size: cover;
}

.user_mode .row-4 {
  padding-top: 50px;
  margin-top: -50px;
}

.row-5 {
  background: url(../images/grass.jpg);
  background-size: 20%;
  box-shadow: inset 0px 35px 20px -20px rgba(0, 0, 0, 0.9);
}

.row-5 .fw-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}

.row-5 .fw-content > .clearAll {
  display: none;
}

.row-5 .fw-content .column1 {
  width: 55%;
  transform: rotate(-1deg);
  padding: 2em;
  background: rgba(0, 0, 0, 0.8);
  border: 3px solid #FFF;
}

.row-5 .fw-content .column1 > div {
  transform: rotate(1deg);
}

.row-5 .fw-content .column1 p {
  color: #FFF;
}

.row-5 .fw-content .column1 .textBlockElement {
  margin: 0;
}

.row-5 .fw-content .column1 .textBlockElement h3 span {
  font-family: "Arial", sans-serif;
  font-weight: 500;
  font-size: 29px;
  color: #fc0;
}

.row-5 .fw-content .column2 {
  width: 35%;
  background-color: #FFF;
  background: url(../images/paper-repeat.jpg);
  transform: rotate(2deg);
  padding: 2em;
}

.row-5 .fw-content .column2 > div {
  transform: rotate(-2deg);
}

.row-5 .fw-content .column2 .textBlockElement h3 span {
  font-family: "nimbus-sans-condensed", sans-serif;
  font-weight: 900;
  font-size: 29px;
  color: #cc0000;
}

.row-5 .fw-content .column2 .textBlockElement h3 span:before {
  content: "";
  padding: 18px;
  margin: 0 10px -5px 0;
  display: inline-block;
  background: url(../images/testimonials-heading.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.row-5 .fw-content .column2 .textBlockElement .text p strong {
  color: #cc0000;
}

.news-option .newsAggregatorElement {
  display: flex;
  flex-flow: row wrap;
}

.news-option .newsAggregatorElement .item {
  width: 48%;
  min-width: 300px;
  padding: 0 0 20px 0;
}

.news-option .newsAggregatorElement .condensed h4 a {
  color: #FFF;
}

.news-option .aggHeader {
  width: 100%;
  color: #FFF;
}

.news-option .details {
  display: flex;
  flex-flow: column;
}

.news-option .dateAuthor {
  order: -1;
  width: 100%;
}

.news-option .item {
  background: none !important;
}

.news-option .details {
  padding: 0 !important;
}

.news-option .item > a, .news-option .teaser {
  display: none  !important;
}

@media screen and (min-width: 769px) and (max-width: 950px) {
  .row-5 .fw-content .column {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .row-5 .fw-content .column {
    width: 100%;
    transform: none;
  }
  .row-5 .fw-content .column > div {
    transform: none;
  }
  .row-2 .fw-content {
    flex-wrap: wrap;
  }
  .row-2 .fw-content .red:before {
    display: none;
  }
  .row-2 .fw-content .red .textBlockElement:before {
    display: none;
  }
  .row-2 .fw-content .column + .column, .row-2 .fw-content .column {
    margin: 4em 0 0 0;
  }
  .extend-br {
    transform: none;
    margin: 0;
  }
}

#home #pageContentContainer {
  padding-top: 0;
}

@media screen and (min-width: 1271px) {
  #topNav {
    min-height: 155px;
  }
  .page-banner.banner-present {
    margin-top: -25px;
  }
}

.coach-highlight .rightTextImage, .coach-highlight .leftTextImage {
  position: relative;
  width: 100% !important;
  max-width: 170px;
  min-height: auto !important;
  background: url(../images/coach-polygon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 20px 30px 10px 30px;
}

.small.coach-highlight .rightTextImage, .small.coach-highlight .leftTextImage {
  max-width: 100px;
}

.large.coach-highlight .rightTextImage, .large.coach-highlight .leftTextImage {
  max-width: 270px;
}

.coach-highlight .rightTextImage:before, .coach-highlight .leftTextImage:before {
  content: '';
  float: left;
  padding-bottom: 100%;
}

.coach-highlight .rightTextImage:after, .coach-highlight .leftTextImage:after {
  content: '';
  display: table;
  clear: both;
}

.coach-highlight .rightTextImage img, .coach-highlight .leftTextImage img {
  border-radius: 100%;
  width: 100% !important;
  height: 100% !important;
}

.sponsors {
  background-color: #1F1F1F;
  background-image: linear-gradient(to bottom, #1F1F1F 0%, #000000 100%);
}

.user_mode .sponsors .column {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 15px;
}

.user_mode .sponsors .column > div {
  margin: 0 15px 15px 15px;
}

.edit_mode .sponsors .columnBounds {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.edit_mode .sponsors .columnBounds > .addPageElementTop {
  min-width: 100%;
  flex-basis: 100%;
}

.edit_mode .sponsors .columnBounds .drag {
  flex-basis: 18%;
}

.sponsors .heroPhotoElement {
  height: 60px;
  display: block;
  margin: 0 !important;
}

.sponsors .heroPhotoElement img {
  width: auto !important;
  display: block;
  max-height: 100%;
  margin: 0 auto;
  max-width: 100%;
}

.custom-link .linkElement h4 {
  text-align: center;
}

.custom-link .linkElement h4 a {
  width: 100%;
  color: #FFF;
  border-bottom: 5px solid #fc0;
  background-color: #cc0000;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 600;
  padding: 20px 2px;
  transition: color .2s ease;
}

.custom-link .linkElement h4 a:hover {
  color: #fc0;
}

.custom-link .linkElement h4 a:after {
  display: none;
}

@media screen and (max-width: 768px) {
  .custom-link .linkElement {
    margin-left: 3vw;
    margin-right: 3vw;
  }
}

@media screen and (max-width: 768px) {
  .about-us .linkElement h4 a {
    width: 100%;
    left: auto;
    top: 0px;
    right: 0;
    transform: none;
    text-align: center;
    font-size: 26px;
  }
}
