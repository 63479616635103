.siteMapElement{
  background: none;
  border: none;
  margin: 0 0 10px 0 !important;
  padding: 0;
  a{
    text-decoration: none;
  }
	.unlimitedHierarchy{
		background: none;
		margin: 0;
		width: auto;
		ul{
			margin-left: .75em;
			border-left: 1px solid #AAA;
			padding-bottom: 1em;
		}
		li{
			display:block;
			padding: 0 0 0 5px;
			clear: both;
			list-style: none;
			background: none;
			li{
				margin-left: 1.5em;
				padding: 0;
			}
			div{
				padding: 5px;
				background: none;
			}
		}
		.lastLi{
			background: none;
			> ul{
				padding-bottom: 0;
				margin-bottom: 1em;
			}
		}
	}
}
