%red-polygon{
  background-color: $primary-color;
  background-size: 100% 100%;
  color: #FFF;
  padding: 25px;
  position: relative;
  z-index: 0;

  &:before{
    content: "";
    width: 105%;
    height: 30px;
    background: url(../images/poly-lb.svg);
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    .edit_mode &{display:none;}
    position: absolute;
    left: 0;
    top: -30px;
    z-index: 0;
  }
  &:after{
    content: "";
    width: 100%;
    height: 20px;
    background: url(../images/poly-lt.svg);
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    .edit_mode &{display:none;}
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: -1;
  }
  > div{
    margin:0;
    padding:0;
    &:before{
      content: "";
      width: 5%;
      height: 100%;
      background: url(../images/poly-lt.svg);
      background-size: cover;
      background-repeat: no-repeat;
      display: block;
      .edit_mode &{display:none;}
      position: absolute;
      top: 0;
      right: -5%;
      z-index: 0;
    }
  }
}

%special-news-aggregator{
  .newsAggregatorElement{
    display: flex;
    flex-flow:row wrap;
    flex: 1 1 auto;
    justify-content: space-around;
    margin:0;
    .item{
      background: $primary-color;
      padding: 15px;
      margin: 30px 0 40px 0;
      @extend %red-polygon;
      &:before{z-index: -1;}
      // min-width: 200px;
      // flex-basis: 100%;
      // max-width: 30%;
      flex-basis: 30%;
      @include respond-to(max, md){
        // max-width: 45%;
        flex-basis: 45%;
      }
      @include respond-to(max, 490px){
        // max-width: 100%;
        flex-basis: 100%;
        &:after, & > div:before{
          display: none;
        }
       }
    }
    .newsItemElement{
      margin-top: -30px !important;
    }
    .newsItemHeader{
      display: flex;
      flex-flow: column;
      > a{
        order: 1;
        display: block;
        background-size: cover;
        background-position: top center;
        padding-bottom: 60%;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,.7);
        margin-bottom: 1em;
        img{
          display: none;
          float: none;
          width: 100%;
          transform: none;
        }
      }
      .newsDate{
        order: 2;
        margin-bottom: 10px;
      }
      h2{order: 3;}
      h2 a{
        display: block;
        line-height: 1.2;
        color: #FFF;
          &:hover{color: $secondary-color;}
      }

    }
    .newsText, .newsPreviewText, .newsItemElement h4, .commentsAndMore, .extendedOptions, .newsAuthor{
      display: none;
    }
  }
}




#news{
  @extend %special-news-aggregator;
}
