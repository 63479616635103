html, body {
  font-family: $primary-font;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  color: $dark-color;
}
body{
  font-size: 100%;
}
%base-link{
  color: $primary-color;
  text-decoration: none;
  transition: color .2s linear;
  &:hover { color: darken($primary-color, 20%); }
}

// .textBlockElement p a {
//   @extend %base-link;
// }


.text, .previewText, .newsText{
  // 1. Text
  p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  // 2. Headers
  // h1, h2, h3, h4, h5, h6{
  //   font-family: $fontOne;
  //   font-weight: 700;
  //   line-height: 1.125;
  //   color: $link-color;
  // }
  // h1{font-size: em(48);}
  // h2{font-size: em(32);}
  // h3{font-size: em(24); text-transform: uppercase;}
  // h4{font-size: em(18); text-transform: uppercase;}
  // h5{font-size: em(16); text-transform: uppercase;}
  // h6{font-size: em(16);}

  // 3. Lists
  ul, ol {
    margin-left: 10px; //Removed from global scope for theme 2. Should become standard going forward.
    margin-bottom: 1em; //Removed from global scope for theme 2. Should become standard going forward.
  }
  ul{list-style-type: disc;}
  // @mixin list-unstyled {
  //   margin-left: 0;
  //   list-style: none;
  // }
  li{margin: 0 0 .5em 10px;}
  // 1. Anchors
  a {
    color: $primary-color-lt;
    text-decoration: none;
    transition: color .2s linear;
    &:hover { color: $primary-color-md; }
  }


  // 3. Inline mods
  b, strong { font-weight: bold; }
  i, em { font-style: italic; }
  small { font-size: .75em; }
  mark { background-color: yellow; }
  s, strike, del { text-decoration: line-through; }
  u { text-decoration: underline; }
  code{
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
  }

  // 4. Code
  code, kbd, pre, samp { font-family: Menlo,Monaco,Consolas,"Courier New",monospace; }
  pre {
    display: block;
    padding: $base-padding;
    margin: 0 0 1rem 0;
    font-size: 90%;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  // 5. Block mods
  blockquote {
    font-family: $secondary-font;
    font-style: italic;
    font-weight: 600;
    font-size: 27px;
    line-height: 1.3333333333;
    // float: right;
    color: $primary-color;
    padding: 0 0 1em 1em;
    margin: 1em;
    &:before{
      content: "“";
      display: inline-block;
      font-weight: 900;
      color: #CCC;
    }
    &:after{
      content: "”";
      display: inline-block;
      font-weight: 900;
      color: #CCC;
    }
    p{display: inline;} // Revisit when liquid
  }





  >{
    p, h1, h2, h3, h4, h5, h6, ol, ul, blockquote, pre{
      &:last-child{margin-bottom: 0;}
    }
  }
  ul, ol{
    margin-left: 10px;
    margin-bottom: 1em;
    li{
      margin-left: 10px;
      > ul, > ol{
        margin-bottom: 0;
      }
    }
  }
}


// 2. Tables
.tableWrapper{
  table{
    font-size: 16px;
    font-family: $secondary-font;
  }
}
.tableElement {
  .odd{
    background-color: transparent;
  }
  tbody tr{
    border-bottom: 2px solid $dark-color;
  }
  table {
    tr {
      th {
        background: #333;
        color: #FFF;
        text-transform: uppercase;
        font-weight: 300;
        @include font-smooth();
      }
    }
  }
  .ajax_sort:hover {
    color: $primary-color;
  }
}
.dataTable{
  td, th{
    padding: 10px;
  }
}


// Headers
%header-2{
  font-family: $primary-font;
  // @include font-smooth();
}
.textBlockElement h3{
  @extend %header-2;
  font-size: rem(29px);
  // @include respond-to(max, 400px){ font-size: rem(26px); }
  font-weight: 600;
  line-height: 1;
  color: $primary-color;
}

h2#displayBodyHeader,
h2#displayBodyHeader span{
  #news &{
    display: none;
  }
}
