/*
  Responsive Sport Header
  version: 1.0
  last modified: 11.3.2015 by Daniel Henderson
  author: Daniel Henderson
  email: dan.henderson@sportngin.com
  dependencies:
    1. Font Awesome https://fortawesome.github.io/Font-Awesome/
    2. sport_header.js
*/

// 1. Variables
// 2. Mixins
// 3. Animations
// 4. Sport Header
// 5. Edit Mode
// 6. Breakpoints

// 1. Variables
// $primary-font: "Oswald", sans-serif;
// $secondary-font: "Merriweather", sans-serif;;
// $primary-color:#F8B128;
// $dark-color:#000;
$transition-default: .2s linear;
$assetPath: 'http://app-assets2.sportngin.com';

// 2. Mixins
@mixin font-smoothing(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
}
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin border-radius($args) {
 -moz-border-radius: $args;
  -webkit-border-radius: $args;
  border-radius: $args;
}
@mixin keyframes($animationName)
{
  @-webkit-keyframes #{$animationName} { @content;}
  @-moz-keyframes #{$animationName}  {@content;}
  @-o-keyframes #{$animationName} {@content;}
  @keyframes #{$animationName} {@content;}
}
@mixin animation($animation) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation;
}

// 3. Animations
@include keyframes(dropdown-season) {
  0% {opacity: 0;top: 90%;}
  100% {opacity: 1;top: 100%;}
}

// 4. Sport Header
#sport-header {
  float: left;
  display: block;
  width: 100%;
	.teamLogo  {
		left: 15px;
		height: 40px;
		width: 120px;
	}
	&.noDrop{
  	#megaDropDown-seasons{
    	padding-right: 10px !important;
  	}
	}
  #snm_advert {
    display: inline-block;
    text-decoration: none;
    padding: 10px;
    background: transparent;
    position: absolute;
    z-index: 1;
    opacity: 0.8;
    @include transition( all 0.3s ease-in-out);
    &:before{
      width: 30px;
      height: 25px;
      position: absolute;
      display: block;
      font: normal 14px/1em $primary-font;
      font-size: 1.75em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      background:url($assetPath+'/app_images/marketing/mobilePhone.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:hover{
      opacity: 1;
    }
		span, strong {
      color: #444;
      font-size: 9px;
      text-align: left;
      display: block;
      line-height: 13px;
      display: block;
      padding-left:33px;
		}
	}
	.sport-tools {
		margin: 10px 0px 20px 0px;
		width: 100%;
		display: block;
		background: none;
		border-top: 1px solid #efefef;
		float: left;
		padding: 0px;
		ul{
  		width: 100%;
  		z-index: 1;
  		position: relative;
  		&:after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: #efefef;
        float: left;
        z-index: -1;
        position: absolute;
        opacity: 1;
        visibility: visible;
        bottom: 0px;
  		}
			li{
				display: inline-block;
				margin: auto;
				background: none;
        border-right: 0px;
        border-left: 0px;
        border-top:0px;
        border-bottom: 3px solid #eee;
        float: left;
        list-style: none;
        @include border-radius(0px);
				a{
					display: inline-block;
					text-align: center;
					padding: 10px;
					background: none;
					border:none;
					list-style: none;
          cursor: pointer;
          color: #777;
          display: block;
          font: 1em/1.3em $primary-font;
          outline: medium none;
          text-decoration: none;
          text-shadow: 1px 1px 1px #fff;
          width: auto;
          @include font-smoothing();
          @include transition( color 0.3s ease-in-out);
				}
				a:before{
					font: normal normal normal 14px/1em FontAwesome;
					padding: 0px;
					display: none;
				}
				&:hover{
  				  @include transition( all 0.3s ease-in-out);
            border-bottom: 3px solid $primary-color;
            a{
              color: #000;
            }
				}
			}
			.selected{
        border-bottom:3px solid $primary-color;
        border-right: 0px;
        border-left: 0px;
        border-top:0px;
        &:hover{
          a{
            color: $primary-color;
          }
        }
        a{
          color: $primary-color;
        }
      }
			#tool-home{
				a{
          span{
            background: none;
            text-indent: 0px;
            width: auto;
            height: auto;
		      }
		      &:before{
  		      content: "\f015";
		      }
	      }
			}
			#tool-roster{
				a:before{
					content: "\f007";
				}
			}
			#tool-game-schedule{
				a:before{
					content: "\f133";
				}
			}
			#tool-player-stats{
				a:before{
					content: "\f080";
				}
			}
			#tool-team-stats{
				a:before{
					content: "\f1fe";;
				}
			}
			#tool-standings{
				a:before{
					content: "\f1b3";
				}
			}
			#tool-bracket{
				a:before{
					content: "\f0e8";
				}
			}
			#tool-photo{
				a:before{
					content: "\f03e";
				}
			}
			#tool-video{
				a:before{
					 content: "\f1c8";
				}
			}
			#tool-posts{
				a:before{
					content: "\f086";
				}
			}
			.tool-yellow-admin{
  			a:before{
    			content: "\f013";
  			}
			}
      .tool-yellow{
  			a:before{
    			content: "\f013";
  			}
			}
      .tool-yellow-manage-team{
        a:before{
          content: "\f024";
        }
      }
		}
		ul.tools {
			width: 100%;
		}
		.button-tool {
			float: left;
		}
		li, #autoRefreshBar li {
			display: inline;
			float: left;
			border-right: 1px solid #eee;
		}
		li.tool-yellow {
			border-color: #edc52c;
			a{
				background: #fddd40 url($assetPath+'/app_images/sport-header/btnsBG-yl.gif') 0 0 repeat-x;
				border-color: #ffc;
				color: #807540;
			}
		}
		li.tool-yellow:hover {
			border-color: #dda300;
			color: #594100;
			a{
				background-color: #fddd40;
				background-position: 0 -80px;
				border-color: #ffc;
				color: #1a1a1a;
			}
		}
		li.tool-yellow:active {
			border-color: #dda300;
			color: #594100;
			a{
				background-color: #fddd40;
				background-position: 0 -160px;
				color: #333;
			}
		}
	}
}
// Sport Crumbs
#sport-crumbs{
	background: #fff !important;
	position: relative;
	float: left;
	display: block;
	width: 100%;
	&.no-crumbs{
  	//
	}
	.sport-crumbs-btns{
    display: block;
    float: left;
    overflow-x: scroll;
    overflow-y:hidden;
    -ms-overflow-style: none;
    width: 100%;
    padding: 10px;
    border-top:1px solid #efefef;
    border-bottom: 1px solid #efefef;
    &::-webkit-scrollbar{
      display: none;
    }
	}
	ul{
    display: inline-block;
    float: left;
    li{
    	float: left;
    	list-style: none;
    	a{
    		padding: 0px;
    		&:before{
      		content: "\f054";
          color: #ccc;
          display: inline-block;
          font: normal 10px/1em FontAwesome;
          padding: 2px;
          position: relative;
          margin: 0 0 0 10px;
    		}
    	}
      &:first-child{
        a:before{
          display: none;
        }
      }
    }
    li.crumbSelected{
      position: relative;
      a{
        color: #000;
        &:before{

        }
      }
      .arrow-down{
        &:after{
          content: "\f077";
          color: #000;
        }
      }
    }
	}
	.sport-crumbs-list{
    ul{
      li{
      	a{
      		padding: 10px 20px;
      	}
      }
  	}
	}
	a{
    color: #666666;
    display: block;
    outline: medium none;
    padding: 10px 20px;
    position: relative;
    text-decoration: none;
    font-weight: normal;
    background: none;
    &.last_child{
      background: none;
      &:hover{
        background: none;
      }
    }
		span{
      display: inline-block;
      font-size: 0.85em;
      padding: 10px;
      text-align: center;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
		}
		span:after{
      border: 1px solid #777;
      border-radius: 20px;
      font: 12px/1em FontAwesome;
      margin: auto;
      padding: 4px;
      text-align: center;
		}
		span.division, span.league, span.team  {
			background: none;
			padding: 5px 0 5px 5px;
		}
		.arrow-down {
      background: none;
      display: block;
      height: auto;
      margin: auto;
      opacity: 0.75;
      padding: 0;
      position: relative;
      right:0px;
      top: 50%;
      width: auto;
      display: inline-block;
      padding-left: 10px;
		}
	}
	a.close .arrow-down {

	}
	a.close:active .arrow-down {

	}
	a:hover{
		color: #000;
		background: none;
		.arrow-down {

		}
	}
	a:active{
		.arrow-down {

		}
	}
	a.wide {
		padding: 0px;
	}
	.arrow-down {
    &:after{
     content: "\f078";
     display: inline-block;
    }
	}
	.sport-crumbs-list-wrapper{
  	display: block;
  	width: 100%;
	}
  .sport-crumbs-list {
  	display: none;
  	text-align: left;
  	float: left;
  	margin: auto;
  	position: absolute;
  	top: 100%;
  	width: 100%;
  	max-width: 50%;
  	z-index: 20;
  	border-left: 1px solid #eee;
  	border-right: 1px solid #eee;
    &.thisCrumb{
      display: block !important;
      @include animation(dropdown-season $transition-default);
    }
  	ul {
  		list-style: none;
  		width: 100%;
  		li{
  			width: 100%;
  			display: block;
  			a {
  				background: #fff;
  				color:#666;
  				border-bottom: 1px solid #eee;
  				display: block;
  				font-size: 12px;
  				line-height: 24px;
  				padding: 5px 10px;
  				text-decoration: none;
  				width: 100%;
  				display: block;
  				&:before{
    				display: none;
  				}
  				img{
  					margin: 0 3px -3px 0;
  				}
  			}
  			a:hover {
  				color:#000;
  				background-color: #fafafa;
  			}
  		}
  		.sport-crumbs-list-close{
    		display: block;
    		background: #fff;
    		a{
      		display: block;
      		float: right;
      		text-align: right;
      		width: 50px;
      		border-bottom: 0px;
      		background: transparent;
      		padding: 15px 15px 10px 10px;
      		&:after{
            content: "\f00d";
            position: relative;
            font: normal normal normal 12px/1em FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: translate(0, 0);
            color: #666;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            border-radius: 40px;
            padding: 3px 5px;
            border:1px solid #666;
            color: #666;
            opacity: 0.7;
            &:hover{
              color: #000;
              border-color: #000;
            }
      		}
      		&:hover{
        		background: #fff;
        		color: #000;
      		}
    		}
  		}
  	}
  }
}
//Sport Colors
.sport-colors {
  clear: both;
  overflow: visible;
  position: relative;
  float: left;
  width: 100%;
  border-bottom: 1px solid #fff;
  .edge{
    display: none;
  }
  h2{
    float: left;
    font: bold 2.3em/1.5em $primary-font;
    max-width: 75%;
    padding: 15px 0px 15px 0px;
    position: relative;
    text-shadow: 1px 1px 0 #fff;
    width: 100%;
    left: 0;
    display: inline-block;
    width: 100%;
    top: 0;
    left: 0;
    a{
      display: inline-block;
      text-decoration: none;
      padding: 0px;
    }
  }
  .teamLogo-large{
    height: 75px !important;
    width: 75px !important;
    position: relative;
    display: inline-block;
    float: left;
    @include border-radius(100%);
    border:3px solid #ddd;
    left: 0;
    top:0;
    margin: 15px;
    background-size: contain;
  }
}
  /* Seasons Mega Drop Down */
  .dropdown-wrapper{
    display: block;
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    z-index: 2;
    &.mdd-active-open{
      #megaDropDown-seasons {
        &:after{
          content: "\f077";
        }
      }
       #megaDropDown-seasons-callout{
        display: block;
        @include animation(dropdown-season $transition-default);
      }
    }
   #megaDropDown-seasons {
    background: #efefef;
    display: block;
    margin: auto;
    outline: medium none;
    text-align: right;
    text-decoration: none;
    width: 100%;
    height: 100%;
    position: relative;
    padding:10px 50px 10px 10px !important;
    background-image: none !important;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    .season{
      color: #999;
      display: block;
      font: normal 10px/1em $primary-font;
      margin: auto;
      width: auto;
    }
    .subseason{
      color: #666;
      font: bold 12px/1em $primary-font;
    }
    &:after{
      content: "\f078";
      display: none;
      position: absolute;
      right: 10px;
      top:10px;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
      color: #fff;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      border-radius: 100px;
      border:1px solid #666;
      color: #666;
      font:normal 12px/1em FontAwesome;
      padding: 4px;
    }
  }
  #megaDropDown-seasons-callout{
    background: #fff;
    padding: 10px;
    text-align: left;
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 230px;
    z-index: 2;
    padding: 15px 15px 20px 15px;
    display: none;
    border:2px solid #eee;
    .season-title {
      display: block;
      font: bold 12px $primary-font;
      padding: 0 0 5px;
      margin: 5px 0;
      span{
        float: left;
      }
    }
    select {
      width: 100%;
      border: 0px;
      border:2px solid #eee;
	    background: #fff;
	    color:#000;
      background-image:url($assetPath+'/app_images/select-down-arrow.svg');
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 27px;
      padding: 7px;
      display: block;
      -webkit-appearance:none;
      -moz-appearance:none;
      appearance:none;
    }
    a {
      display: block;
      font-size: 12px;
      padding: 2px 5px;
      text-decoration: none;
      background-color: #efefef;
      text-align: center;
      color: #666;
      border: 1px solid #fff;
      &:hover{
        background: #efefef;
        border: 1px solid #e6e6e6;
      }
    }
    a.close {
      background: #fff;
      border: 1px solid #777;
      @include border-radius(100%);
      color: #777;
      display: block;
      float: right;
      font-size: 12px;
      font-weight: bold;
      margin: 0;
      padding: 0px 7px 2px 7px;

      &:hover {

      }
    }
    a.current {
      background: #efefef none repeat scroll 0 0;
      border-radius: 0;
      color: #333;
      font-size: 11px;
      font-weight: bold;
      padding: 5px 10px;
      text-align: center;
    }
    .footer {
      margin: 10px 0 0;
    }
    optgroup option {
      margin: 0;
      padding: 0 0 0 10px;
    }
  }
   #megaDropDown-seasons.mdd-active{
    cursor: pointer;
    &:after{
      display: block;
    }
    &:hover{
      background-color: #efefef;
    }
  }
}
// 5. Edit Mode
.teamPage #rapidTabs.rapidTabsConstruction{
  background: none;
}
.yieldPageContent{
  clear: both;
}
//6. Breakpoints
@media only screen and (max-width:1025px) {
  #sport-header{
  	.sport-tools {
  		ul li a{
        font-size: .9em;
      }
    }
  }
}
@media only screen and (max-width:769px) {
  #sport-header{
    #sport-crumbs{
      ul li.crumbSelected{
          position:static;
      }
      .sport-crumbs-list{
        max-width: 100%;
    	}
      a{
        span{
          font-size: 0.70em;
          &:after{
            font: 10px/1em FontAwesome;
          }
        }
        .arrow-down{
          margin-left: 0px;
        }
      }
    }
    .sport-tools {
      ul li a{
        padding: 10px 2px;
        font-size: 0em;
        &:before{
          display: block;
        }
      }
    }
  }
  /* Seasons Mega Drop Down */
  .dropdown-wrapper{
    max-width: 100%;
    width: 100%;
    &:before{
      display: none;
    }
     #megaDropDown-seasons {
      .season{
        font-size: 10px;
      }
      .subseason{
        font-size: 9px;
      }
      &:after{

      }
    }
     #megaDropDown-seasons-callout{
      max-width: 100%;
    }
  }
  // .sport-colors{
  //   .teamLogo-large{
  //     height: 45px !important;
  //     width: 45px !important;
  //     @include border-radius(100%);
  //   }
  // }
}



//
.abs0{
  display: none;
}
.sport-colors .teamLogo-large{
  border: none;
  border-radius: none;
  min-width: 75px;
  @include respond-to(max, 300px){
    display: none;
  }
}
.sport-colors{
  display: flex;
  flex-flow: row ;
  align-items: center;
  justify-content: center;
  float: none;
  h2{
    margin-right: auto;
  }
}



//
.widgetHierarchy li{
  background: none;
  border: none;
}
.statTable{
    font-size: 16px;
    font-family: $secondary-font;
  .odd{
    background-color: transparent;
  }
  tbody{
    background: #FFF;
  }
  tbody tr{
    border-bottom: 2px solid $dark-color;
  }
  tr {
    th {
      background: #333;
      color: #FFF;
      text-transform: uppercase;
      font-weight: 300;
      @include font-smooth();
    }
  }
  .ajax_sort:hover {
    color: $primary-color;
  }
  td, th{
    font-size: 16px;
    line-height: 1;
    padding: 10px;
    border: none;
  }
  .hightlight{
    background: $light-color;
  }
 .teamName, .teamName-tiny{
   font-weight: 300;
 }
}
