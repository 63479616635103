.photo-slidehow{
  z-index: 1;
  position: relative;

  .user_mode &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
  }
  .prev_btn, .next_btn, .image_title, .controls{
    display: none !important;
  }
  .crop{
    padding-top: #{((295 / 1024) * 100)}% !important;
  }
  .galleryElement_gallery .image {
    width: 100% !important;
    height: auto !important;
    left: 0 !important;
  }
}
