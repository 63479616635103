%twitter-custom{
  $twitter-color: #28B2EF;
  .twitterFeedElement{
    margin:0;

  }
  .tweet-container{
    background-color: transparent;
    border: 0;
    color: #FFF;
  }
  .tweet-header{
    padding: 0 0 20px 0;
    &:before{
      content: "Latest Tweets";
      display: block;
      font-family: $secondary-font;
      font-weight: 900;
      color: #FFF;
      font-size: 2.57143rem;
      line-height: 1.2;
    }
    .header{
      text-align: left;
      a{
        color:$secondary-color;
        &:hover{
          color: #FFF;
        }
      }
    }
  }
  .tweet-avatar, .header-name{display:none;}
  .header-screen-name{
    display: inline-block;
    &:before{
      content:"Follow us @";
      position: relative;
      border: 0;
      width: auto;
      top: auto;
      display: inline-block;;
      margin: 0;
    }
  }
  .tweets{
    border:0;
    background-color: transparent;
    max-height: initial;
  }
  .tweet{
    border:0;
    // padding: 0 0 20px 0;
    padding: 0 0 20px 55px;
    background: url(../images/twitter.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-y: 5px;
  }
  .tweet-text{
    padding:0;
    font-size:14px;
  }
  .tweet-container-footer{
    display: none;
  }
  .tweet-footer{
    background-color: transparent;
    padding: 0;
  }
  .tweet-date, .tweet-screen-name{
    display: none;
  }

  .tweet-name:hover, .tweet-hover:hover, .tweet-screen-name, .tweet-date, .tweet-text a, .tweet-text a:hover, .tweet-date:hover, .tweet-url, .tweet-url:hover{
    color: #999;
  }
  .tweet-icons{
    font-size: 13px;
  }
  .tweet-link{

  }
}
