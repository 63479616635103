#siteFooter{
  background: linear-gradient(to bottom, #222, #000);
  padding: 20px 0;
  ul{
    list-style: none;
    color: #FFF;
    max-width: 800px;
  }
  > ul {
    text-align: left;
    position: relative;
    padding-left: 55px;
    li{
      display: inline-block;
      margin-left: 5px;
      &:first-child{
        width: 100%;
      }
    }
    &:before{
      content: '';
      display: block;
      width:45px;
      height:60px;
      background: url(../images/footer-crest.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  a{
    text-decoration:none;
    color: #DDD;
    transition: color .2s linear;
    &:hover{
      color: $primary-color;
    }
  }
}
#poweredByNGIN{
  a{margin-left: -12px}
  img{
    margin-bottom: -6px;
  }
}

@include respond-to(max, md){
  #siteFooter > ul{
    text-align: center;
    padding: 0;
    li{margin: 0;}
    &:before{
      width: 100%;
      position: relative;
      top:auto;
      left: auto;
      transform: none;
      background-position: center;
    }
  }
}
