// Navigation - Desktop
// First Level
// Second Level
// Third Level
// ——————————————————————————————————————————
// First Level
#topNav{
  table, tbody, tr, td{
    display: block;
  }
  tr{
    display: flex;
    flex-flow: row nowrap;
    align-items:stretch;
    justify-content: space-between;

    // border: 1px solid tomato;
    // padding: 2px;
  }
  td{
    display: flex;
    flex: 1 1 auto;
    align-items: stretch;
    justify-content: center;

    // border: 1px solid mediumslateblue;
    // padding: 2px;
  }
  a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    &:hover{text-decoration: none;}

    // border: 1px solid mediumseagreen;
    // padding: 2px;
  }

}
// ——————————————————————————————————————————
// Second Level
#topNav{
  td{
    ul{
      box-shadow: none;
      top: 100%;
      left: 0;
      li{
        a{
          background: none;
          border: none;
          width: auto;
          padding: 0;
        }
        &:hover, &.hover{
          background-color: transparent;
          > a{
            background: none;
            border: none;
          }
        }
      }
    }
  }
}
// ——————————————————————————————————————————
// Third Level
#topNav{
  ul ul{
    border: none;
    top: 0;
    left: 100%;
  }
}


// Theme Style
#topNav{
  min-height: 200px;
  // Typography
  a{
    line-height: 1.2;
  }
  tbody{
    background-color: $primary-color;
    padding: 15px;
    max-width: 980px;
    margin: 0 0 0 auto;
    position: relative;

    &:after{
      content:"";
      position: absolute;
      top:100%;
      right:0;
      display: block;
      height: 30px;
      width: 100%;
      max-width: 980px;
      background: url(../images/angle.svg) no-repeat;
      background-size: 100% 100%;
    }
    &:before{
      content: $team-name;
      position: absolute;
      top:100%;
      right:0;
      display: block;
      padding: 35px 15px 15px 35px;
      max-width: 980px;
      background: url(../images/team-name.svg) no-repeat;
      background-size: 100% 100%;
      font-family: $secondary-font;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 30px;
    }

  }
  // First Level
  td{
    > a{
      padding: 5px 3px;
      color: $white-color;
      font-weight: 900;
    }
    &:hover, &.hover{
      > a{
        // background-color: $dark-color;
        color: #ffcc00;
      }
    }
    &.selected{
      > a{
        background-color: $dark-color;
        color: $secondary-color;
      }
    }
    &.disabled{
      > a{
        background-color: darken($primary-color, 5%);
        color: #000;
      }
    }
  }

  // Second Level
  td{
    ul{
      box-shadow: 0px 0px 5px 0px rgba(#000, .5);
      &.dropdown{
        // border-top: 5px solid transparent;
      }
      li{
        + li {border-top: 1px solid #000;}
        a{
          padding: 10px 15px;
          min-width: 120px;
          color: #FFF;
          background: #222;
        }
        &:hover > a, &.hover > a{
          background: $primary-color;
          color: $secondary-color;
        }
        a.disabled{
          background: #666;
          color: #000;
        }
        // &:hover > a, &.hover > a, a{
        //   background: $primary-color;
        // }
      }
    }
  }
}



// SITE HEADER
.siteContainer{
  &:before{
    content:'';
    display:block;
    width:100vw;
    position: absolute;
    top:0;
    left: 50%;
    margin-left: -50vw;

    height: 152px;
    background: url(../images/chalkboard-bg.png) top left repeat;
    margin-top: -20px;
    padding: 10px 0 0 0;
    transform: rotate(1deg);
    box-shadow: inset 0px -40px 0px -30px rgba(0, 0, 0, 0.5);
  }
}
.site-header{
  position: absolute;
  top: 0;
  left:0;
  display: block;
  width: 100%;
  max-width: 262px;
  height:110px;
  z-index: 99;
  margin-left: 10px;
  a{
    display: block;
    width: 100%;
    width: 100%;
    height:110px;
    background: url(../images/header-logo.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
  }
}


@media screen and(max-width:1270px){
  #topNav{
    tbody{
      max-width:100%;
    }
  }
  .site-header{
    top: 60px;

  }
  .siteContainer:before{
    height: 200px;
    transform: rotate(0deg);
  }
}
