.scroller-content{
  .user_mode &{
    display: none;
  }
  .edit_mode &{
    .codeElement{
      &:before{
        content: "The following code is used it import an RSS feed into the scrolling text on the Home page. Copy and paste the code from a news aggregator's RSS feed here, to update the scrolling text.";
        text-align:center;
        color: #FFF;
        padding: 10px;
        max-width: 620px;
        margin: 0 auto;
        display: block;

      }
      pre{
        background: #222;
        color: #FFF;
        -webkit-font-smoothing: antialiased;
        text-align: center;
      }
    }
  }
}


#pageContentContainer .tickerScroller {
    position: relative;
    margin: auto;
    width: 100%;
    height: 25px;
    overflow: hidden;
    border-radius: 5px;
    // top: -15px;
}
#pageContentContainer .tickerScroller .scroller {
    position: absolute;
    width: 4000px;
    left: 0px;
}
#pageContentContainer .leftgradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    z-index: 99;
    left: 0;
    height: 40px;
    width: 60px;
}
#pageContentContainer .rightgradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 4%, rgba(0, 0, 0, 0.11) 15%, rgba(0, 0, 0, 0.99) 99%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 99;
    right: 0;
    height: 40px;
    width: 60px;
}
#pageContentContainer .tickerScroller .scroller a {
    position: relative;
    display: inline-block;
    float: left;
    color: #ffffff;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
}
#pageContentContainer .tickerScroller .scroller a:hover {
    color: red;
}
#pageContentContainer .tickerScroller .scroller a h1 {
    text-indent: 0;
    font-size: 16px;
    line-height: 1.5em;
}



/* liScroll styles */

.tickercontainer { /* the outer div with the black border */
border: 1px solid #000;
background: #fff;
width: 738px;
height: 27px;
margin: 0;
padding: 0;
overflow: hidden;
}
.tickercontainer .mask { /* that serves as a mask. so you get a sort of padding both left and right */
position: relative;
left: 10px;
top: 8px;
width: 718px;
overflow: hidden;
}
ul.newsticker { /* that's your list */
position: relative;
left: 750px;
font: bold 10px Verdana;
list-style-type: none;
margin: 0;
padding: 0;

}
ul.newsticker li {
float: left; /* important: display inline gives incorrect results when you check for elem's width */
margin: 0;
padding: 0;
background: #fff;
}
ul.newsticker a {
white-space: nowrap;
padding: 0;
color: #ff0000;
font: bold 10px Verdana;
margin: 0 50px 0 0;
}
ul.newsticker span {
margin: 0 10px 0 0;
}
