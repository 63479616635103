@import "_twitter-custom.scss";

%flex-columns{
  .column, .columnBounds{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .columnBounds{
    flex-wrap: wrap;
    > .addPageElementTop{
      min-width: 100%;
    }
    > div{
      flex-basis: 50%;
    }
  }
  .pageElement{
    margin: 0;
  }
}





// Multi-use classes
.full-width{
  @extend %full-width;
  margin-right: 0;
}
.full-width-container{
  @extend %full-width;
  margin-right: 0;
  padding: 3vw;
  .fw-content{
    @extend %standard-width;
    // padding: 0 $content-container-padding;
  }
  &.oneColumn{
    .pageElement{
      margin-right: 0;
    }
  }
}
.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.layoutContainer{
  @extend %full-width-image-background;
}


%about-us-home{
  position: relative;
  .linkElement{
    h4{
      margin-bottom: 30px;
      a{
        background: $secondary-color;
        display: block;
        position: absolute;
        top: 0;
        left: 75%;
        transform: translate(-50%, -50%);
        padding: 10px 20px;
        color: #000;
        text-decoration: none;
        font-family: $secondary-font;
        font-size: 52px;
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;
        z-index: 99;
        white-space: nowrap;
        .edit_mode &{
          position: relative;
          top:auto;
          left: auto;
          transform: none;
        }
        &:after{
          content: "\00bb";
          font-family: fontawesome;
        }
        @include respond-to(max, sm){
          white-space: initial;
        }
        &:hover{
          background: $primary-color;
          color: $secondary-color;
        }
      }
    }
  }
}

// CUSTOM

.row-2{
  background: url(../images/two-tone.svg), #FFF;
  background-size: cover;
  background-repeat: no-repeat;

  // @extend %about-us-home;
  .fw-content{
    padding: 3em 0;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    .column{
      // max-width: 650px;
      + .column{
        margin-left: 5em;
      }
    }
  }
}

.red{
  background-color: $primary-color;
  background-size: 100% 100%;
  color: #FFF;
  padding: 25px;
  position: relative;
  z-index: 0;

  &:before{
    content: "";
    width: 105%;
    height: 30px;
    background: url(../images/poly-lb.svg);
    background-size: cover;
    background-repeat: no-repeat;

    display: block;
    .edit_mode &{display:none;}
    position: absolute;
    left: 0;
    top: -30px;
    z-index: 0;
  }
  &:after{
    content: "";
    width: 100%;
    height: 20px;
    background: url(../images/poly-lt.svg);
    background-size: cover;
    background-repeat: no-repeat;

    display: block;
    .edit_mode &{display:none;}
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: -1;
  }
  .textBlockElement{
    margin:0;
    padding:0;
    &:before{
      content: "";
      width: 5%;
      height: 100%;
      background: url(../images/poly-lt.svg);
      background-size: cover;
      background-repeat: no-repeat;

      display: block;
      .edit_mode &{display:none;}
      position: absolute;
      top: 0;
      right: -5%;
      z-index: 0;
    }
  }
}
.extend-br{
  transform: translate(40px, 40px);
  margin-top: -41px;
}


.home-aggregator{
  border: 10px solid #cc0000;
  padding: 25px;
  background-color: #FFF;
}

#siteContainer2.one_panel #panelTwo,
#siteContainer2.one_panel #yieldContent{
  background: none;
}


.row-3{
  background-color: #FFF;
  // padding: 30px 0;
  .user_mode &{
    padding-bottom: 80px;
  }
  .fw-content{
    display: flex;
    flex-flow: row wrap;

    .column1{
      flex: 2 0 auto;
      align-self: center;
    }
    .column2{
      flex: 1 0 auto;
      align-self: flex-end;
      margin-top:3em;
    }
  }
  .heroPhotoElement img{
    max-width: 390px;
    margin: 0 auto;
    .user_mode &{
      margin-bottom:-70px;
    }
  }
  .textBlockElement{
    h3 span{
      font-family: $primary-font;
      font-weight: 500;
      font-size: 26px;
      color: $primary-color;
    }
  }
}

.row-4{
  background: url(../images/brushed-metal.png);
  background-size: cover;
  .user_mode &{
    padding-top: 50px;
    margin-top: -50px;
  }
}

.row-5{
  background: url(../images/grass.jpg);
  background-size: 20%;
  box-shadow: inset 0px 35px 20px -20px rgba(0, 0, 0, 0.9);
  .fw-content{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    > .clearAll{display: none;}
    .column1{
      width: 55%;
      // flex: 2 0 auto;
      @include rotate(1, left);

      padding: 2em;
      background: rgba(0,0,0,.8);
      border: 3px solid #FFF;
      p{color: #FFF;}
      .textBlockElement{
        margin: 0;
        h3 span{
          font-family: $primary-font;
          font-weight: 500;
          font-size: 29px;
          color: $secondary-color;
        }
      }

    }
    .column2{
      width: 35%;
      // flex: 1 0 auto;
      background-color: #FFF;
      background: url(../images/paper-repeat.jpg);

      @include rotate(2, right);
      padding: 2em;
      .textBlockElement{
        h3 span{
          font-family: $secondary-font;
          font-weight: 900;
          font-size: 29px;
          color: $primary-color;
          &:before{
            content:"";
            padding: 18px;
            margin: 0 10px -5px 0;
            display: inline-block;
            background: url(../images/testimonials-heading.png);
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        .text p strong{
          color: $primary-color;
        }
      }
    }
  }
}


.news-option{
  .newsAggregatorElement{
    display: flex;
    flex-flow: row wrap;
    // justify-content: space-between;
    .item{
      width: 48%;
      min-width: 300px;
      padding: 0 0 20px 0;
    }
    .condensed h4 a{
      color: #FFF;
    }
  }
  .aggHeader{
    width: 100%;
    color: #FFF;
  }
  .details{
    display: flex;
    flex-flow: column;
  }
  .dateAuthor{
    order: -1;
    width: 100%;
  }
  .item{ background: none !important; }
  .details{ padding: 0 !important; }
  .item > a, .teaser{ display: none  !important; }
}


@media screen and (min-width: 769px) and (max-width: 950px){
  .row-5{
    .fw-content{
      .column{
        width: 48%;
      }
    }
  }
}
@include respond-to(max, md){
  .row-5{
    .fw-content{
      .column{
        width: 100%;
        transform:none;
        > div{transform:none;}
      }
    }
  }
  .row-2{
    .fw-content{
      flex-wrap: wrap;
      .red{
        &:before{display: none;}
        .textBlockElement{
          &:before{display: none;}
        }
      }
      .column + .column, .column{
        margin: 4em 0 0 0;
      }
    }
  }
  .extend-br{
    transform: none;
    margin: 0;
  }
}


// Twitter
.row-4{
  @extend %twitter-custom;
}



// Revisions

#home #pageContentContainer{
  padding-top: 0;
}

@media screen and (min-width: 1271px){
  #topNav{
    min-height: 155px;
  }
  .page-banner.banner-present {
    margin-top: -25px;
  }
}


// Coaches
.coach-highlight{
  .rightTextImage, .leftTextImage{
    position: relative;
    width: 100% !important;
    // height: 100% !important;
    max-width: 170px;
    @at-root .small.coach-highlight{
      .rightTextImage, .leftTextImage{
        max-width: 100px;
      }
    }
    @at-root .large.coach-highlight{
      .rightTextImage, .leftTextImage{
        max-width: 270px;
      }
    }
    min-height: auto !important;
    // height: 250px; // Toggle force square
    background: url(../images/coach-polygon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px 30px 10px 30px;
    // display: flex;
    // flex-flow: column;
    // justify-content: center;
    // align-items: stretch;
    @include aspect-ratio(1,1);
    img{
      border-radius: 100%;
      width: 100% !important;
      height: 100% !important;
    }
  }
}


.sponsors{
  $min-padding: 15px;
  background-color: #1F1F1F;
  background-image: linear-gradient(to bottom, #1F1F1F 0%, #000000 100%);
  .user_mode & .column{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: $min-padding;
    > div{
      margin: 0 $min-padding $min-padding $min-padding;
    }
  }
  .edit_mode & .columnBounds{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    > .addPageElementTop{
      min-width: 100%;
      flex-basis: 100%;
    }
    .drag {
      flex-basis: 18%;
    }
  }

  .heroPhotoElement{
    height: 60px;
    display: block;
    margin: 0 !important;
    img{
      width: auto !important;
      display: block;
      max-height: 100%;
      margin: 0 auto;
      max-width: 100%;
    }
  }

}

.custom-link{
  .linkElement h4{
    text-align: center;
    a{
      width: 100%;
      color: #FFF;
      border-bottom: 5px solid #fc0;
      background-color: #cc0000;
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 600;
      padding: 20px 2px;
      transition: color .2s ease;
      &:hover{
        color: #fc0;
      }
      &:after{display: none;}
    }
  }
  @media screen and (max-width:768px){
    .linkElement{
      margin-left: 3vw;
      margin-right: 3vw;
    }
  }
}

.about-us{
  @extend %about-us-home;
  @include respond-to(max, md){
    .linkElement h4 a{
      width: 100%;
      left: auto;
      top: 0px;
      right: 0;
      transform: none;
      text-align: center;
      // font-weight: 400;
      font-size: 26px;
    }
  }
}
