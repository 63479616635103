// 1. Base Styles
* { box-sizing: border-box; }

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #000;
  width: 100%;
  height: 100%;
}
body{
  overflow-x: hidden;
}

// Rows - Content Area
.siteContainer{
  position: relative;
  overflow-x: hidden;
  z-index:1;
}
.siteContainer, #topNav table, #siteFooter > ul{
  max-width: $max-content-width;
  width: 100%;
  margin: 0 auto;
}
#siteFooter{ width:100%; }

// Columns - Panels
#panelOne{
  width: 100%;
  // Disable 2 Columns
  .user_mode &{
    // display: none;
  }
}
#panelTwo{
  // width:80%;
  // padding-left: $base-padding;
  width: 100%;

}
#panelOne, #panelTwo{
  box-sizing: border-box;
  margin:0;
}
#pageContentContainer{
  padding: 2em;
}
@include respond-to(max, md){
  #panelOne, #panelTwo{
    float: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #pageContentContainer{
    display: flex;
    flex-direction: column;
    padding: 1vw;
  }
}


// Columns - Page Elements
@include respond-to(max, md){
  .layoutContainer{
    .pe25, .pe33, .pe50, .pe66{
      width:100%;
    }
  }
}


@media screen and (min-width: 450px) and (max-width: 768px){
  .threeColumn502525, .threeColumn252550{
    .pe25{
      width:50%;
    }
    .pe50{
      width: 100%;
    }
  }
}


// Page Elements
.layoutContainer{

}
.pageEl{

}


// Current section Edit Mode
.subNav .drag, .subNav .lock, .cmsNavButton a{
  padding: 6px 20px 6px 8px;
  margin: 1px 0;
}
.relatedSections, #leftHandGraphics, #panelOne .cmsButton{
  display: none;
}


body:not(#home):not(#news) #panelTwo{
  padding-bottom: 4em;
  #displayBodyContent{
    background-color: #FFF;
    background: url(../images/paper-repeat.jpg);
    padding: 2em;
    // @include respond-to(min, md){
    //   @include rotate(1, right);
    // }
  }
}
#home #panelTwo #yieldContent {
  padding: 0;
}
#panelOne{
  margin-bottom: 2em;
}
