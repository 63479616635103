.eventAggregatorElement{
  .even, .odd{
    background-color: transparent;
  }
  a{
    text-decoration: none;
  }
  .aggHeader{
    @extend %agg-headers;
  }
  .vevent{
    line-height: 1.2;
    border: none;
    border-bottom: 2px solid #EEE;
    padding: 0 0 10px 0;
    min-height: 85px;

    + .vevent{
      padding-top: 10px;
      min-height: 85px;
    }
    &.odd{
      background-color: transparent;
    }
    .dateImage{
      width: 70px;
      padding-left: 5px;
      .month{
        font-size: rem(18px);
        font-weight: 600;
        line-height: 1.5;
        color: $light-color;
        text-transform: uppercase;
      }
      .date{
        font-size: rem(50px);
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        color: #222;
        letter-spacing: -3px;
      }
    }
    .summary, .details{
      padding-left: 80px;
    }
    .summary{
      font-family: $primary-font;
      font-size: rem(18px);
      font-weight: 400;
      line-height: 1.5;
      a{
        color: $primary-color-md;
      }
    }
    .details{
      padding-top: 10px;
    }
    .time, .location, .location:before{
      font-family: $primary-font;
      font-size: rem(12px);
      font-style: italic;
      line-height: 1;
    }
    .time{
      a{display: none;}
    }
    .location{
      &:before{
        content: "/ ";
      }
    }
  }
  .tags, .description{
    display: none;
  }
}
.eventAggregatorElement{
  .condensed{
    h4{
      font-family: $primary-font;
      font-size: rem(18px);
      line-height: 1.5;
      a{
        color: $primary-color-md;
      }
    }
    .dateAuthor{
      font-family: $primary-font;
      font-size: rem(12px);
      font-style: italic;
      line-height: 1;
      padding-top: 10px;
    }
  }
  .expanded{
    .newsItemElement{
      h2{
        font-family: $primary-font;
        font-size: rem(18px);
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        a{
          color: $primary-color-md;
          &:hover{text-decoration: none;}
        }
      }
    }
    .newsDate, .newsAuthor{
      font-family: $primary-font;
      font-size: rem(12px);
      font-style: italic;
      line-height: 1;
      padding-top: 10px;
    }
  }
}


@mixin sm-event-aggregator{
  // .eventAggregatorElement{
    .vevent .dateImage {
      width: 100%;
      padding: 0;
      position: relative;
      margin-bottom: 1em;
    }
    .vevent {
      .summary, .details {
        padding-left: 80px;
        padding: 0;
      }
    }
  // }
}



.eventAggregatorElement{
  .column &{
    @include respond-to(max, 290px){
      @include sm-event-aggregator;
    }
  }
  .pe33 &{
    @media screen and (min-width: 651px) and (max-width: 770px){
      @include sm-event-aggregator;
    }
  }
  .threeColumn255025 .pe25 &{
    @include respond-to(min, 651px){
      @include sm-event-aggregator;
    }
  }
  .threeColumn502525 .pe25 &, .threeColumn252550 .pe25 &{
    @media screen and (min-width: 450px) and (max-width: 575px), (min-width: 769px) and (max-width: 1009px){
      @include sm-event-aggregator;
    }
  }
  // .twoColumn5050 .pe50 &{
  //   @media screen and (min-width: 576px) and (max-width: 875px){
  //     @include sm-event-aggregator;
  //   }
  // }
}
